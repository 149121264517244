import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateBomsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { omit } from 'lodash';
import { updateBomScheme } from 'src/modules/schemes';
import { getBomsTable } from 'src/modules/selectors/bom.selector';

interface IUpdateBomsProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  ids: IdsArray;
}

export const UpdateBoms = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  ids,
}: IUpdateBomsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const boms = useSelector(getBomsTable);

  const bomsFullData = boms.filter((reason) => ids.includes(reason.id));

  const validate = useValidate(updateBomScheme);

  const formik = useFormik({
    initialValues: bomsFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateBomsRequest({
          data: data.map((item) => omit(item, 'site')),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.initialValues);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('boms.update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {bomsFullData.map((bom, index) => (
              <DrawerBodySectionContent key={bom.id}>
                <DrawerBodySectionTitle>{`${bom.name}`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.name`}
                    variant="outlined"
                    required
                    fullWidth
                    label={t('boms.name')}
                    name={`${index}.name`}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    label={t('boms.site')}
                    placeholder={t('boms.site')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
