import React from 'react';
import { map } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelCombobox';
import { useFetchUsersCombobox } from 'src/modules/utils';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { ComboBoxOption } from 'src/components/ComboBox';
import { supervisorRoles } from 'src/config';
import { getSupervisorsAndGMsBySitesComboboxList } from 'src/modules/selectors/user';

type IFilterPanelSupervisorsComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  filterId: string;
  siteId?: number;
  onRemoveFilter?: (filterId: string) => void;
  fieldAsId?: 'id' | 'name';
  value?: number | null;
};

export const FilterPanelSupervisorsCombobox = ({
  filterId,
  fieldAsId = 'id',
  value,
  siteId,
  ...props
}: IFilterPanelSupervisorsComboboxProps) => {
  const currentRole = useSelector(getCurrentRole);

  const fetchSupervisorsCombobox = useFetchUsersCombobox({
    role: {
      inq: supervisorRoles,
    },
  });

  React.useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole, siteId]);

  const supervisors = useSelector(
    getSupervisorsAndGMsBySitesComboboxList,
    shallowEqual,
  );

  const options = (React.useMemo(
    () =>
      map(supervisors, (user) => ({
        id: user[fieldAsId],
        name: user.name,
      })),
    [fieldAsId, supervisors],
  ) as unknown) as ComboBoxOption[];

  const comboboxValue = options.find(({ id }) => id === value);

  return (
    <FilterPanelCombobox
      value={comboboxValue ?? null}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
