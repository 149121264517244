import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateBom,
  IdsArray,
  IFilter,
  IBomModel,
  IUpdateBom,
  PayloadWithFilters,
} from '../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushBomState = createAction('Flush bom data in store');

// Creation actions
export const createBomRequest = createAction<PayloadWithFilters<ICreateBom>>(
  'Make request to create a new bom',
);
export const createBomSuccess = createAction<IBomModel>(
  'New bom has been created successfully',
);
export const createBomFailed = createAction('Cannot create a new bom');

// Fetch boms actions
export const getBomListRequest = createAction<IFilter>(
  'Make request to fetch bom list',
);
export const getBomListSuccess = createAction<IBomModel[]>(
  'Boms list has been fetched successfully',
);
export const getBomListFailed = createAction('Cannot fetch bom list');

// get boms count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getBomCountRequest = createAction<IFilter>(
  'Make request to get bom count',
);
export const getBomCountSuccess = createAction<ICountResponse>(
  'Boms count has been fetched successfully',
);
export const getBomCountFailed = createAction('Cannot get bom count');

// delete actions
export const deleteBomRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete boms',
);
export const deleteBomSuccess = createAction('Boms successfully deleted');
export const deleteBomFailed = createAction('Cannot delete boms');

// Updating actions
export const updateBomsRequest = createAction<PayloadWithFilters<IUpdateBom[]>>(
  'Make request to update boms',
);
export const updateBomsSuccess = createAction('Boms successfully updated');
export const updateBomsFailed = createAction('Cannot update boms');

// Fetch combobox list actions
export const getBomComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of boms',
);
export const getBomComboboxListSuccess = createAction<Partial<IBomModel>[]>(
  'Combobox list of boms has been fetched successfully',
);
export const getBomComboboxListFailed = createAction(
  'Cannot fetch combobox boms list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Bom').takeExportActions();

export const exportBomRequest = exportReportRequest;
export const exportBomSuccess = exportReportSuccess;
export const exportBomFailed = exportReportFailed;
