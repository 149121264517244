import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExceptionsFromEmployeesReport } from 'src/components/Reports/ExceptionsFromEmployeesReport';
import { clients } from '../../modules/constants';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IPerryEllisExceptionsFromEmployeesProps {
  siteId?: number;
  employeeId?: number;
}

export const PerryEllisExceptionsFromEmployees = ({
  siteId,
  employeeId,
}: IPerryEllisExceptionsFromEmployeesProps) => {
  const { t } = useTranslation();

  const isSiteSelected = Boolean(siteId);

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && (
        <ExceptionsFromEmployeesReport
          client={clients.perryEllis}
          employeeId={employeeId}
          siteId={siteId}
          tableName={t(
            'perry_ellis_reports.table_names.exception_from_employees',
          )}
        />
      )}
    </>
  );
};
