import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistoryEmployee,
  useHistoryEmployeeComposeWhere,
  useHistoryEmployeeTableFiltersConfiguration,
} from 'src/modules/utils/hooks/changelog.hooks';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { ReportPage } from 'src/components/ReportPage';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { useCreateReportFiltersConfigWithDefaultOptions } from 'src/modules/utils/hooks/reportPage.hooks';
import {
  HistoryEmployeeFilterPanel,
  IHistoryEmployeeFilterPanelFilters,
} from 'src/pages/Changelog/components/HistoryEmployeeFilterPanel';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { defaultStyles, JsonView } from 'react-json-view-lite';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from '../../modules/utils/dateWrapper';
import { AnyObject } from '../../modules/types';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

const defaultFilters: IHistoryEmployeeFilterPanelFilters = {
  employeeId: undefined,
};

const HistoryEmployee: React.FC = () => {
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = React.useState<
    IHistoryEmployeeFilterPanelFilters
  >(defaultFilters);

  const reportUrl = React.useMemo(
    () => `/changelog/history/employee/${appliedFilters?.employeeId}`,
    [appliedFilters?.employeeId],
  );

  const filterFieldsConfiguration = useHistoryEmployeeTableFiltersConfiguration();

  const { filterFields } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useHistoryEmployeeComposeWhere({
    employeeId: +filterFields.employeeId.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useHistoryEmployee(reportUrl, where, !appliedFilters?.employeeId);

  const computedList = React.useMemo(
    () =>
      (data as AnyObject[]).map((item) => ({
        ...item,
        diff: (
          <>
            <JsonView
              data={item.diff}
              shouldExpandNode={() => false}
              style={defaultStyles}
            />
          </>
        ),
        timestamp: composeDate(
          item.timestamp,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
      })),
    [data],
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IHistoryEmployeeFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [{ id: 'employeeId', label: t('changelog.history.employee') }],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer>
          <ReportPage hideNoData>
            <ReportPageItemContainer>
              <ReportTableToolbar
                reportName={t('main_menu.changelog.history.employee.title')}
              ></ReportTableToolbar>
            </ReportPageItemContainer>
            <ReportPageItemContainer>
              <HistoryEmployeeFilterPanel
                hideSyncBtn
                filtersOptionsConfig={filtersOptionsConfig}
                onApplyFilters={(filtersToApply) => {
                  setAppliedFilters(filtersToApply);
                }}
                onRemoveAllFilters={() => setAppliedFilters(defaultFilters)}
              />
            </ReportPageItemContainer>
            <ReportPageItemContainer fullHeight>
              {!appliedFilters?.employeeId && (
                <RequiredFiltersAlert i18nKey="common.use_filters_alert" />
              )}
              {appliedFilters?.employeeId && (
                <ReportTable
                  data={computedList as any}
                  count={countData.count}
                  isDataLoading={isDataLoading || isCountDataLoading}
                  tableUniqueKey={reportUrl}
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={limit}
                  currentPageNumber={page}
                  onSort={handleSort}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={(_, page) => handlePageChange(page)}
                />
              )}
            </ReportPageItemContainer>
          </ReportPage>
        </PageContentChildContainer>
      </PageContentWithTopToolbar>
    </>
  );
};

export default HistoryEmployee;
