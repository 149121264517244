import React from 'react';
import { RequiredFiltersAlert } from '../RequiredFiltersAlert';

export const SelectSiteAlert = () => {
  return (
    <RequiredFiltersAlert
      i18nKey="filters.site_alert"
      values={{ site: 'Site' }}
    />
  );
};
