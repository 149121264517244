import React from 'react';
import {
  HeadCell,
  HeadCellWithVisibilityFlag,
  ITableSyncProps,
  TableOrder,
} from '../../../components/EnhancedTable';
import {
  cloneDeep,
  findIndex,
  forEach,
  has,
  isEmpty,
  pickBy,
  set,
  some,
} from 'lodash';
import {
  AnyObject,
  IExportColumnsData,
  IFilterInclude,
  IWhere,
} from '../../types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fromPairs } from 'lodash';
import { guessTimezone } from '../dateWrapper';
import { getItemFromLocalStorage } from '../localStorageWrapper';
import { setToLocalStorageVisibleHeadCells } from './table';

export interface ComposeFiltersProps
  extends Omit<ITableSyncProps, 'order' | 'where'> {
  orderBy: string;
  headCells: HeadCell[];
  where: { [key: string]: IWhere };
  order: TableOrder;
  localizationKeys?: string[];
}

export const useEnhancedTableHelpers = (props: ComposeFiltersProps) => {
  const { include } = props;

  const escapeProperty = (property: string) => {
    const delimiter = [' ', '-', '/', '\\'];
    if (some(delimiter, (d) => property.includes(d))) {
      return `\`${property}\``;
    }
    return property;
  };

  /**
   * Get relation name from property
   * @param _property - Property name (might have dot notation, for example 'name' or 'user.firstName' or 'supervisor.user.firstName')
   */
  const getRelationName = useCallback((_property: string): {
    relations: string[] | null;
    property: string;
  } => {
    const property_name_length = 2;
    const splitted = _property.split('.');
    if (splitted.length >= property_name_length) {
      const relations = splitted.slice(0, -1);
      const property = splitted[splitted.length - 1];
      return {
        relations,
        property,
      };
    } else {
      return {
        property: splitted[0],
        relations: null,
      };
    }
  }, []);

  /**
   * Compose order array for NOT inclusion data
   * @param _orderBy - Property name
   * @param _order - order ASC | DESC
   */
  const composeOrder = (_orderBy: string, _order: TableOrder) => {
    const { property, relations } = getRelationName(_orderBy);

    return isEmpty(relations)
      ? [`${escapeProperty(property)} ${_order}`]
      : undefined;
  };

  /**
   * Compose where object for NOT inclusion data
   * @param _where - Initial where object
   */
  const composeWhere = (_where: IWhere) => {
    return pickBy(_where, (where_value, where_key) => {
      const { relations } = getRelationName(where_key as string);
      return isEmpty(relations) && where_value !== null;
    });
  };

  /**
   * Set order object recursively
   * @param relation - Relation name
   * @param filterIncludes - Include array
   * @param _orderBy - Ordered property name
   * @param _order - Order direction
   */
  const setOrderDeep = useCallback(
    (
      relation: string,
      filterIncludes: IFilterInclude[],
      _orderBy: string,
      _order: TableOrder,
    ) => {
      if (!isEmpty(filterIncludes)) {
        // we need to remove all previous order scopes
        forEach(filterIncludes, (filterInclude: IFilterInclude) => {
          if (has(filterInclude, 'scope.order')) {
            delete filterInclude.scope?.order;
          }
        });
        // try to find index of relation item
        const relation_index = findIndex(
          filterIncludes,
          (i) => i.relation === relation,
        );
        // if we found the relation among current include array, try to set `where` object
        if (relation_index > -1) {
          filterIncludes[relation_index] = set(
            filterIncludes[relation_index],
            `scope.order`,
            [`${escapeProperty(_orderBy)} ${_order}`],
          );
        } else {
          // otherwise check if filterIncludes has nested include array
          forEach(filterIncludes, (filterInclude: IFilterInclude) => {
            if (filterInclude && has(filterInclude, 'scope.include')) {
              setOrderDeep(
                relation,
                filterInclude!.scope!.include!,
                _orderBy,
                _order,
              );
            }
          });
        }
      }
    },
    [],
  );

  /**
   * Create inclusion order array
   * @param _include - Initial include array
   * @param _orderBy - Property name to order by
   * @param _order - asc | desc
   */
  const updateIncludeOrder = useCallback(
    (_include: IFilterInclude[], _orderBy: string, _order: TableOrder) => {
      // try to get relations names and property name
      const { relations, property } = getRelationName(_orderBy as string);
      // if there are any relations, we will try to compose inclusion `order` object
      if (relations && !isEmpty(relations) && !isEmpty(_include)) {
        const relation = relations[relations?.length - 1];
        if (relation) {
          setOrderDeep(relation, _include, property, _order);
        }
      }
    },
    [getRelationName, setOrderDeep],
  );

  /**
   * Set where object recursively
   * @param relation - Relation name
   * @param filterIncludes - Include array
   * @param whereProperty - Where property name
   * @param value - Where property value
   */

  const setWhereDeep = useCallback(
    (
      relation: string,
      filterIncludes: IFilterInclude[],
      whereProperty: string,
      value: any,
    ) => {
      if (!isEmpty(filterIncludes)) {
        // try to find index of relation item
        const relation_index = findIndex(
          filterIncludes,
          (i) => i.relation === relation,
        );
        // if we found the relation among current include array, try to set `where` object
        if (relation_index > -1) {
          if (value === null) {
            try {
              delete (filterIncludes as AnyObject)[relation_index].scope?.where[
                whereProperty
              ];
            } catch (e) {}
          } else {
            filterIncludes[relation_index] = set(
              filterIncludes[relation_index],
              `scope.where[${whereProperty}]`,
              value,
            );
          }
        } else {
          // otherwise check if filterIncludes has nested include array
          forEach(filterIncludes, (filterInclude: IFilterInclude) => {
            if (filterInclude && has(filterInclude, 'scope.include')) {
              setWhereDeep(
                relation,
                filterInclude!.scope!.include!,
                whereProperty,
                value,
              );
            }
          });
        }
      }
    },
    [],
  );

  /**
   * Create inclusion where object
   * @param _include - Initial include array
   * @param _where - Initial where object
   */
  const updateIncludeWhere = useCallback(
    (_include: IFilterInclude[], _where: IWhere) => {
      // setting up `where` object
      forEach(_where, (value, name) => {
        // try to get relations names and property name
        const {
          relations: whereRelations,
          property: whereProperty,
        } = getRelationName(name);
        // if there are any relations, we will try to compose inclusion `where` object
        if (whereRelations && !isEmpty(whereRelations) && !isEmpty(_include)) {
          const relation = whereRelations[whereRelations?.length - 1];
          if (relation) {
            setWhereDeep(relation, _include, whereProperty, value);
          }
        }
      });
    },
    [getRelationName, setWhereDeep],
  );

  /**
   * Compose inclusion object
   */
  const composeInclusion = useCallback(
    (_orderBy: string, _order: TableOrder, _where: IWhere) => {
      if (!include || isEmpty(include)) {
        return include;
      }
      const newInclude = cloneDeep(include);

      updateIncludeOrder(newInclude, _orderBy, _order);
      updateIncludeWhere(newInclude, _where);

      return newInclude;
    },
    [include, updateIncludeOrder, updateIncludeWhere],
  );

  return {
    setOrderDeep,
    setWhereDeep,
    composeInclusion,
    updateIncludeWhere,
    updateIncludeOrder,
    composeWhere,
    composeOrder,
    getRelationName,
  };
};

/**
 * A custom hook to compose filters
 */
export const useComposeFilters = (
  props: ComposeFiltersProps,
): ((
  exportAllPages: boolean,
  headCells?: HeadCell[],
) => IExportColumnsData) => {
  const {
    composeInclusion,
    composeWhere,
    composeOrder,
  } = useEnhancedTableHelpers(props);
  const { t } = useTranslation();

  return useCallback(
    (exportAllPages, headCells?) => {
      const fields = {};
      const { order, rowsPerPage, page, where, orderBy } = props;
      const columnNamesLocalization = {};
      const limit = exportAllPages ? undefined : rowsPerPage;
      const offset = exportAllPages ? 0 : page * rowsPerPage;

      (headCells ?? props.headCells).forEach((cell) => {
        (fields as any)[cell.id] = true;
        (columnNamesLocalization as any)[cell.id] = cell.label;
      });

      // collect localization map
      const localization = fromPairs(
        (props.localizationKeys || []).map((key) => [key, t(key)]),
      );

      const timezone = guessTimezone();

      return {
        filter: {
          offset,
          limit,
          include: composeInclusion(orderBy, order, where),
          where: composeWhere(where) as any,
          order: composeOrder(orderBy, order),
          fields,
        },
        columnNamesLocalization,
        localization,
        timezone,
      };
    },
    [composeInclusion, composeOrder, composeWhere, props, t],
  );
};

export const useHeadCellsWithVisibilityFlag = (
  headCells: HeadCell[],
  tableName: string,
) => {
  const [headCellsWithVisibilityFlag, setHeadCells] = React.useState<
    HeadCellWithVisibilityFlag[]
  >([]);

  const setHeadCellsWithVisibilityFlag = (
    headCells: HeadCellWithVisibilityFlag[],
  ) => {
    const cellsToPersist = headCells.reduce<string[]>((visibleCells, cell) => {
      if (cell.isVisible) {
        visibleCells.push(cell.id);
      }

      return visibleCells;
    }, []);

    setToLocalStorageVisibleHeadCells(tableName, cellsToPersist);
    setHeadCells(headCells);
  };

  React.useEffect(() => {
    const persistedCellsState = getItemFromLocalStorage<string[]>(
      'tablesConfiguration',
    );

    setHeadCells(
      headCells.map((cell) => ({
        ...cell,
        isVisible: !(
          persistedCellsState && persistedCellsState[tableName as any]
        )
          ? true
          : persistedCellsState[tableName as any].includes(cell.id),
      })),
    );
  }, [headCells, tableName]);

  return { headCellsWithVisibilityFlag, setHeadCellsWithVisibilityFlag };
};
