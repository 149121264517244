import React from 'react';
import { useTranslation } from 'react-i18next';

import DailyUphBySite from './DailyUphBySite';
import { Report, ReportPage } from 'src/components/ReportPage';
import WeeklyUphBySiteAndShift from './WeeklyUphBySiteAndShift';
import { OptoroExceptionsFromProduction } from './ExceptionFromProduction';
import { WeeklyUphBySiteAndEmployee } from './WeeklyUphBySiteAndEmployee';
import { OptoroExceptionsFromEmployees } from './ExceptionFromEmployees';
import { WeeklyUphBySite } from './WeeklyUphBySite';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import UphChart from 'src/components/ReportPage/UphChart';
import { OptoroPulseSummary } from './PulseSummary';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  IOptoroReportsFilterPanelFilters,
  OptoroReportsFilterPanel,
} from './OptoroReportsFilterPanel';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { OptoroDaily } from './Daily';
import { DailyUphBySiteAndShift } from './DailyUphBySiteAndShift';
import { DailyUphBySiteAndEmployee } from './DailyUphBySiteAndEmployee';
import { OptoroIncentiveIndividual } from './IncentiveIndividual';

const reportsDefaultFilters: IOptoroReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

const client = clients.optoro;

export const OptoroReports = () => {
  const { t } = useTranslation();

  const reportProps = useReportsProps(client);

  const hasUserAccessToUploadData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.optoro].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IOptoroReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
      },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      { id: 'date', label: t('productions_uph_reports.date') },
      { id: 'shiftId', label: t('productions_uph_reports.shift.name') },
      { id: 'employeeId', label: t('productions_uph_reports.employee') },
      { id: 'startDate', label: t('common.startDate') },
      { id: 'endDate', label: t('common.endDate') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IOptoroReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={client}>
        <ReportTableToolbar reportName={t('main_menu.reports.optoro_main')}>
          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <SelectHeadCells />
            </ReportPageActionsGroupItem>
          </ReportPageActionsGroupContainer>

          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <ReportPageExport reportProps={reportProps} />
            </ReportPageActionsGroupItem>

            {hasUserAccessToUploadData && (
              <ReportPageActionsGroupItem>
                <UploadImportingFile clientKey={client} />
              </ReportPageActionsGroupItem>
            )}
          </ReportPageActionsGroupContainer>
        </ReportTableToolbar>
        <ReportPageItemContainer>
          <OptoroReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportProps.dailyReport}>
          <ReportPageItemContainer fullHeight>
            <OptoroDaily
              siteId={appliedFilters.siteId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.dailyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <UphChart
              reportTypeId={reportProps.dailyUphBySite.reportTypeId}
              client={client}
              filters={{
                startDate: appliedFilters.startDate,
                endDate: appliedFilters.endDate,
              }}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <DailyUphBySite
              siteId={appliedFilters.siteId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.dailyUphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <DailyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.dailyUphBySiteAndEmployee}>
          <ReportPageItemContainer fullHeight>
            <DailyUphBySiteAndEmployee
              siteId={appliedFilters.siteId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.exceptionsFromEmployees}>
          <ReportPageItemContainer fullHeight>
            <OptoroExceptionsFromEmployees
              siteId={appliedFilters.siteId}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.exceptionsFromProduction}>
          <ReportPageItemContainer fullHeight>
            <OptoroExceptionsFromProduction siteId={appliedFilters.siteId} />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.weeklyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <UphChart
              reportTypeId={reportProps.weeklyUphBySite.reportTypeId}
              client={client}
              filters={{
                year: appliedFilters.year,
                week: appliedFilters.week,
              }}
            />
          </ReportPageItemContainer>

          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySite
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.weeklyUphBySiteAndEmployee}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySiteAndEmployee
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.weeklyUphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              shiftId={appliedFilters.shiftId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.weeklyGrossMargin}>
          <ReportPageItemContainer>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.pulseSummary}>
          <ReportPageItemContainer fullHeight>
            <OptoroPulseSummary siteId={appliedFilters?.siteId} />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.incentiveIndividual}>
          <ReportPageItemContainer fullHeight>
            <OptoroIncentiveIndividual
              siteId={appliedFilters?.siteId}
              year={appliedFilters?.year}
              week={appliedFilters?.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportProps.incentiveIndirect}>
          <ReportPageItemContainer fullHeight>
            <OptoroIncentiveIndividual
              siteId={appliedFilters?.siteId}
              year={appliedFilters?.year}
              week={appliedFilters?.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
