import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject, ICountResponse } from '../../modules/types';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from '../../modules/utils/hooks/reports.hooks';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IProps {
  siteId?: number;
  startDate?: string;
  endDate?: string;
}

export const McEntireDaily: React.FC<IProps> = ({
  siteId,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'transactionDate',
  };

  const where = React.useMemo(() => {
    const and = [];

    if (startDate) {
      and.push({ transactionDate: { gte: startDate } });
    }
    if (endDate) {
      and.push({ transactionDate: { lte: endDate } });
    }

    return {
      siteId,
      ...(and.length ? { and } : {}),
    };
  }, [siteId, startDate, endDate]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    AnyObject[]
  >('/mc-entire/daily-report', []);
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`/mc-entire/daily-report/count`, {
    count: 0,
  });

  const columnNames = [
    'Date',
    'Date Time',
    'Factory Code',
    'Item No',
    'Production Line Code',
    'Production Order No',
    'Quantity',
    'Unit of Measure Code',
    'Weight',
    'startDatetime',
    'startDatetimeTZ',
    'keyDate',
    'siteId',
    'siteName',
    'transactionDate',
    'shiftId',
    'shiftName',
    'shiftStartDate',
  ];

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () =>
      columnNames.map((i) => ({
        id: i,
        orderConfig: { orderBy: i },
        label: i,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data, t],
    ),
  });

  return (
    <>
      {!siteId && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {siteId && (
        <ReportTable
          data={data as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey="/mc-entire/daily-report"
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
