import React from 'react';
import { ExceptionsFromProductionReport } from 'src/components/Reports/ExceptionsFromProductionReport';
import { clients } from '../../modules/constants';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

const headCellsToOmit = ['id', 'siteId', 'shiftId', 'date', 'endDate'];

interface IExpeditorsExceptionsFromProductionProps {
  siteId?: number;
}

export const ExpeditorsExceptionsFromProduction = ({
  siteId,
}: IExpeditorsExceptionsFromProductionProps) => {
  const isSiteSelected = Boolean(siteId);

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && (
        <ExceptionsFromProductionReport
          siteId={siteId}
          client={clients.expeditors}
          headCellsToOmit={headCellsToOmit}
        />
      )}
    </>
  );
};

export default ExpeditorsExceptionsFromProduction;
