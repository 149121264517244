import * as React from 'react';
import { i18n } from 'src/modules/utils';
import { externalUrls, paths, tabsPaths } from './paths';
import { Employees } from '../pages/Employees/Employees';
import { PositionsList } from '../pages/Positions/PositionsList';
import { ShiftPage } from '../pages/Shifts';
import { AutomaticPointsRejectReason } from '../pages/AutomaticPointsRejectReasons';
import PoliciesList from '../pages/Policies/PoliciesList';
import PoliciesCreate from '../pages/Policies/PoliciesCreate';
import PoliciesUpdate from '../pages/Policies/PoliciesUpdate';
import ClientsList from '../pages/Clients/ClientsList';
import { StaffingProvidersList } from '../pages/StaffingProviders/StaffingProvidersList';
import { Site } from '../pages/Sites';
import { SkillsList } from '../pages/Skills/SkillsList';
import { RolesList } from '../pages/Roles/RolesList';
import { StandardsList } from '../pages/Standards/StandardsList';
import { PricingPage } from '../pages/Pricing/';
import { DepartmentsList } from '../pages/Departments/DepartmentsList';
// import LogtimesListApproved from '../pages/Logtimes/LogtimesListApproved';
// import LogtimesListUnapproved from '../pages/Logtimes/LogtimesListUnapproved';
import { IssueTypesList } from '../pages/IssueTypes/IssueTypesList';
import { UsersList } from '../pages/Users/UsersList';
// import SupervisorView from '../pages/SupervisorView';
import { Profile } from '../pages/Profile';
import { PointTypesList } from '../pages/PointTypes/PointTypesList';
// import PayboardList from '../pages/Payboard/PayboardList';
// import PayboardDetailedList from '../pages/Payboard/PayboardDetailedList';
import PayboardWeek from '../pages/Payboard/PayboardWeek';
import NewPayboardWeek from '../pages/Payboard/NewPayboardWeek';
import Landing from '../pages/Landing';
import { TerminationReasonsList } from '../pages/TerminationReasons/TerminationReasonsList';
import RecruitingDashboardSummary from '../pages/RecruitingDashboard/RecruitingDashboardSummary';
import RecruitingDashboardDetails from '../pages/RecruitingDashboard/RecruitingDashboardDetails';
import ClosedPositionsList from '../pages/OpenPositions/ClosedPositionsList';
import OpenPositionCreate from '../pages/OpenPositions/OpenPositionCreate';
import OpenPositionUpdate from '../pages/OpenPositions/OpenPositionUpdate';
import ApplicantsCreate from '../pages/Applicants/ApplicantsCreate';
import ApplicantsUpdate from '../pages/Applicants/ApplicantsUpdate';
import ApplicantsList from '../pages/Applicants/ApplicantsList';
import ApplicantsViewList from '../pages/Applicants/ApplicantsView/ApplicantsViewList';
import OpenPositionViewList from '../pages/OpenPositions/OpenPositionsView/OpenPositionViewList';
// import PayboardDailyHoursSummaryReport from '../pages/Payboard/PayboardDailyHoursSummaryReport';
import { OptiturnCredentialsList } from '../pages/OptiturnCredentials/OptiturnCredentialsList';
import ProductionsList from '../pages/Productions/ProductionsList';
import { TrainingVideos } from '../pages/TrainingVideos';
import { Checklist } from '../pages/Checklist';
import { CostPlusWeeklyList } from '../pages/CustomerReports/CostPlusWeeklyList';
import { ProductionDataFileUploader } from '../components/ProductionDataFileUploader';
import { RestoreEntities } from '../pages/RestoreEntities';
import { ExpeditorsGapsDaily } from '../pages/Expeditors/GapsDaily';
import { SiteTaktExportingSettingsList } from '../pages/TaktSettings';
import { ExecutiveReports } from '../pages/ExecutiveReports/Reports';
import Splash from '../pages/FinancialTracker/Splash';
import { BrookfieldReports } from '../pages/BrookfieldReports/Reports';
// import { CardinalHealthReports } from '../pages/CardinalHealthReports/Reports';
// import { DormanReports } from '../pages/DormanReports/Reports';
// import { LevisReports } from '../pages/LevisReports/Reports';
import { OptoroReports } from '../pages/Optoro/Reports';
import { ExpeditorsReports } from '../pages/Expeditors/Reports';
import { PerryEllisReports } from '../pages/PerryEllis/Reports';
import { McEntireReports } from '../pages/McEntire';
import { TargetReports } from '../pages/Target';
import { WilliamsSonomaReports } from '../pages/WilliamsSonoma';
import { InvoiceList } from '../pages/Invoice/InvoiceList';
// import InvoicesDeprecatedCreate from '../pages/Invoice/InvoiceDeprecatedCreate';
// import InvoiceDeprecatedUpdate from '../pages/Invoice/InvoiceDeprecatedUpdate';
import { InvoiceWeekDetails } from '../pages/Invoice/InvoiceDetails';
import { FilledAssociates } from '../pages/FilledAssociates/FilledAssociates';
import PayboardSummaryByWorkWeek from '../pages/Payboard/PayboardSummaryByWorkWeek';
import { CreateIssueInIssueTracker } from '../pages/IssueTracker/CreateIssueInIssueTracker';
import IssueTrackerIssuesList from '../pages/IssueTracker/IssueTrackerIssuesList';
import { IssueTrackerIssueDetails } from '../pages/IssueTracker/IssueTrackerIssueDetails';
import CreateWiki from '../pages/Wiki/CreateWiki';
import UpdateWiki from '../pages/Wiki/UpdateWiki';
import DetailWikiByIdView from '../pages/Wiki/DetailWikiByIdView';
import DetailWikiByTypeView from '../pages/Wiki/DetailWikiByTypeView';
import WikiViewList from '../pages/Wiki/WikiViewList';
import WikiSetupList from '../pages/Wiki/WikiSetupList';
import { DailyHours } from '../pages/TimeKeeping/DailyHours';
// import { AreaBaseBaselineCreate } from '../pages/Baseline/AreaBasedBaseline/AreaBasedBaselineCreate';
// import AreaBasedBaselineList from '../pages/Baseline/AreaBasedBaseline/AreaBasedBaselineList';
// import AreaBasedBaselineUpdate from '../pages/Baseline/AreaBasedBaseline/AreaBasedBaselineUpdate';
// import { CpuBaseBaselineCreate } from '../pages/Baseline/CpuBasedBaseline/CpuBasedBaselineCreate';
// import CpuBasedBaselineList from '../pages/Baseline/CpuBasedBaseline/CpuBasedBaselineList';
// import CpuBasedBaselineUpdate from '../pages/Baseline/CpuBasedBaseline/CpuBasedBaselineUpdate';
import BaselineList from '../pages/Baseline/BaselineList';
import BaselineUpdate from '../pages/Baseline/BaselineUpdate';
import BaselineCreate from '../pages/Baseline/BaselineCreate';
import { ProductionSettingsPage } from '../pages/ProductionSettings';
import PayboardSummaryByWorkAndSupervisorWeek from '../pages/Payboard/PayboardSummaryByWorkAndSupervisorWeek';
// import { BarretReports } from '../pages/Barrett/Reports';
import { CarpartsReports } from '../pages/Carparts/Reports';
import { TaylorFarmsReports } from '../pages/TaylorFarms/Reports';
// import MonthInvoiceDeprecatedUpdate from '../pages/Invoice/MonthInvoiceDeprecatedUpdate';
import { InvoiceMonthDetails } from '../pages/Invoice/InvoiceDetails/InvoiceMonthDetails';
import PageSettings from '../pages/PageSettings/';
import PolicesCreateFromPageSettingsConfig from '../pages/Policies/PolicesCreateFromPageSettingsConfigs';
import { clients } from '../modules/constants';
import Changelog from 'src/pages/Changelog';
import HistoryEmployee from 'src/pages/Changelog/HistoryEmployee';
import { SonyReports } from '../pages/Sony/Reports';
// import PayboardHistoricalList from '../pages/Payboard/PayboardHistoricalList';
import NewHistoricalPayboardWeek from '../pages/Payboard/NewHistoricalPayboardWeek';
import { NfiReports } from '../pages/Nfi';
import { Dashboard } from 'src/pages/Dashboard';
import { Points } from 'src/pages/Points';
import { EmployeesQuestions } from 'src/pages/Employees/EmplloyeesQuestions';
import { EmployeesSkillFlex } from 'src/pages/Employees/EmployeesSkillFlex';
import { SupervisorView, TimePunches } from 'src/pages/TimeKeeping';
import { Payboard } from '../pages/TimeKeeping/Payboard';
import { PayboardHistorical } from '../pages/TimeKeeping/PayboardHistorical';
import { Incentive } from '../pages/TimeKeeping/Incentive';
import { KpiSettings } from 'src/pages/KpiSettings';
import Kpi from 'src/pages/Kpi/Kpi';
import KpiReport from 'src/pages/Kpi/KpiReport';
import { JobAbandonment } from 'src/pages/JobAbandonment';
import { TerminatedEmployees } from '../pages/TerminatedEmployees';
import { Absentee } from '../pages/Absentee';
import { Downtime } from '../pages/Downtime';
import { WeeklyHoursDashboard } from '../pages/TimeKeeping/WeeklyHoursDashboard';
import { ExecutiveSummary } from '../pages/ExecutiveSummary';
import { Costs } from '../pages/Payboard/Costs';
import { TurnoverDashboard } from '../pages/TurnoverDashboard';
import Error404 from 'src/pages/Error404';
import { AppVersion } from 'src/pages/AppVersion';
import { EmployeesReviews } from 'src/pages/Employees/EmployeesReviews';
import { IncentivePaymentSettings } from 'src/pages/IncentivePaymentSettings';
import { EmployeesCreateWithoutPaymentInfo } from 'src/pages/Employees/EmployeesCreateWithoutPaymentInfo';
import { AreaSetup } from 'src/pages/Areas';
import { InvoiceSettings } from 'src/pages/Invoice/InvoiceSettings';
import { Metatags } from 'src/pages/Metatags';
import { PointCollection } from 'src/pages/PointsCollection';
import { IpWhitelist } from 'src/pages/IpWhitelist';

type IPrivateRouteConfig = Record<
  string,
  {
    element: React.ReactNode;
    title: string;
    hideInPagesCombobox?: boolean;
  }
>;

export const privateRoutes: IPrivateRouteConfig = {
  [externalUrls.guide]: {
    element: <Error404 />,
    title: 'guide.page_title',
  },
  [paths.APP_VERSION]: {
    element: <AppVersion />,
    title: '',
  },
  [paths.LANDING]: {
    element: <Landing />,
    title: 'main_menu.landing',
  },
  [paths.PROFILE]: {
    element: <Profile />,
    title: 'common.my_profile',
  },
  [paths.REPORTS_RECRUITING_DASHBOARD]: {
    element: <RecruitingDashboardSummary />,
    title: 'main_menu.chart_reports.recruiting_dashboard',
  },
  [paths.REPORTS_DAILY_HOURS]: {
    element: <DailyHours />,
    title: 'main_menu.chart_reports.daily_hours',
  },
  [paths.REPORTS_WEEKLY_HOURS_DASHBOARD]: {
    element: <WeeklyHoursDashboard />,
    title: 'main_menu.chart_reports.weekly_hours_dashboard',
  },
  [paths.REPORTS_COSTS]: {
    element: <Costs />,
    title: 'main_menu.payboard_costs.main_item',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.optoro)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.optoro.upload',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.expeditors)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.expeditors.upload',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.sony)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.sony.upload',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.carparts)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.carparts.upload',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.perryEllis)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.perry-ellis.upload',
  },
  [paths.IMPORT_UPLOAD_DATA(clients.williamsSonoma)]: {
    element: <ProductionDataFileUploader />,
    title: 'main_menu.williams-sonoma.upload',
  },
  [paths.REPORTS_TURNOVER_DASHBOARD]: {
    element: <TurnoverDashboard />,
    title: 'main_menu.turnover_dashboard',
  },
  [paths.POSITIONS]: {
    element: <PositionsList />,
    title: 'positions.list_title',
  },
  [paths.AUTOMATIC_POINTS_REJECTION_REASON]: {
    element: <AutomaticPointsRejectReason />,
    title: 'automaticPointsRejectReason.menu_title',
  },
  [paths.SHIFTS]: {
    element: <ShiftPage />,
    title: 'shifts.list_title',
  },
  [paths.AREA_LIST]: {
    element: <AreaSetup />,
    title: 'main_menu.area.list',
  },
  [paths.CLIENTS]: {
    element: <ClientsList />,
    title: 'clients.list_title',
  },
  [paths.SITES]: {
    element: <Site />,
    title: 'sites.list_title',
  },
  [paths.KNOWLEDGE_BASE_DOCUMENTATION_PAGES]: {
    element: <WikiViewList />,
    title: 'main_menu.knowledge.documentation_pages',
  },
  [paths.WIKI_SETUP_LIST]: {
    element: <WikiSetupList />,
    title: 'main_menu.knowledge_setup.pages_list',
  },
  [paths.WIKI_SETUP_CREATE]: {
    element: <CreateWiki />,
    title: 'main_menu.knowledge_setup.create_page',
  },
  [paths.WIKI_SETUP_UPDATE]: {
    element: <UpdateWiki />,
    title: 'main_menu.knowledge_setup.update_page',
  },
  [paths.WIKI_PAGE_BY_TYPE('quickReferencesGuide')]: {
    element: <DetailWikiByTypeView />,
    title: 'main_menu.knowledge.quick_references_guide',
  },
  [paths.WIKI_PAGE_BY_TYPE('faq')]: {
    element: <DetailWikiByTypeView />,
    title: 'main_menu.knowledge.faqs',
  },
  [paths.WIKI_PAGE_BY_ID()]: {
    element: <DetailWikiByIdView />,
    title: 'main_menu.wiki.setup_page_detailed_view',
  },
  [paths.ISSUE_TRACKER_LIST]: {
    element: <IssueTrackerIssuesList />,
    title: 'main_menu.knowledge.support_request.list',
  },
  [paths.ISSUE_TRACKER_CREATE]: {
    element: <CreateIssueInIssueTracker />,
    title: 'main_menu.knowledge.support_request.create',
  },
  [paths.ISSUE_TRACKER_ISSUE_DETAILS]: {
    element: <IssueTrackerIssueDetails />,
    title: 'main_menu.issue_tracker.details',
  },
  [paths.FILLED_ASSOCIATES]: {
    element: <FilledAssociates />,
    title: 'main_menu.chart_reports.filled_associates',
  },
  [paths.EMPLOYEES]: {
    element: <Employees />,
    title: 'employees.list_title',
  },
  [paths.EMPLOYEES_QUESTIONS]: {
    element: <EmployeesQuestions />,
    title: 'main_menu.employees_questions',
  },
  [paths.EMPLOYEES_ACTIVE_BUT_NOT_PRESENT]: {
    element: <JobAbandonment />,
    title: 'employees.active_but_not_present.page_title',
  },
  [paths.EMPLOYEES_TERMINATED]: {
    element: <TerminatedEmployees />,
    title: 'employees.terminated.page_title',
  },
  [paths.EMPLOYEES_CREATE_WITHOUT_PAYMENT_INFO]: {
    element: <EmployeesCreateWithoutPaymentInfo />,
    title: 'employees.create_without_payment_info',
  },
  [paths.HOOKY_FOLKS]: {
    element: <Absentee />,
    title: 'main_menu.absentee',
  },
  // [paths.EMPLOYEES_FILL_IN_QUESTIONNAIRE]: {
  //   element: <FillInQuestionnaire />,
  //   title: 'main_menu.employees_fill_in_questionnaire',
  // },
  // [paths.EMPLOYEES_FILL_IN_OVERDUE_QUESTIONNAIRE]: {
  //   element: <FillInOverdueQuestionnaire />,
  //   title: 'main_menu.employees_fill_in_overdue_questionnaire',
  // },
  [paths.QUESTIONNAIRE_HISTORY]: {
    element: <EmployeesReviews />,
    title: 'main_menu.employees_questionnaire_history',
  },
  // @@@TODO: add redesign for these pages, they are not the first priority
  // [paths.QUESTIONNAIRE_HISTORY_UPDATE]: {
  //   element: <QuestionnaireHistoryUpdate />,
  //   title: 'main_menu.employees_questionnaire_history_update',
  // },
  // [paths.QUESTIONNAIRE_OVERDUE]: {
  //   element: <QuestionnaireOverdue />,
  //   title: 'main_menu.employees_questionnaire_overdue',
  // },
  [paths.DEPARTMENTS]: {
    element: <DepartmentsList />,
    title: 'departments.list_title',
  },
  [paths.TIME_KEEPING_PUNCHES]: {
    element: <TimePunches />,
    title: 'main_menu.time_keeping.time_punches',
  },
  [paths.TIME_KEEPING_SUPERVISOR_VIEW]: {
    element: <SupervisorView />,
    title: 'main_menu.time_keeping.supervisor_view',
  },
  // [paths.LOGTIMES_APPROVED]: {
  //   element: <LogtimesListApproved />,
  //   title: 'logtimes.list_title_approved',
  // },
  // [paths.LOGTIMES_UNAPPROVED]: {
  //   element: <LogtimesListUnapproved />,
  //   title: 'logtimes.list_title_unapproved',
  // },
  [paths.USERS]: {
    element: <UsersList />,
    title: 'users.list_title',
  },
  [paths.STAFFING_PROVIDERS]: {
    element: <StaffingProvidersList />,
    title: 'staffing_prov.list_title',
  },
  [paths.SKILLS]: {
    element: <SkillsList />,
    title: 'skills.list_title',
  },
  [paths.ROLES]: {
    element: <RolesList />,
    title: 'main_menu.roles_list',
  },
  [paths.TERMINATION_REASONS]: {
    element: <TerminationReasonsList />,
    title: 'terminationReasons.list_title',
  },
  [paths.EMPLOYEE_SKILL_FLEX]: {
    element: <EmployeesSkillFlex />,
    title: 'main_menu.skillflex.list',
  },
  // [paths.SUPERVISOR_VIEW]: {
  //   element: <SupervisorView />,
  //   title: 'supervisor_view.page_title',
  // },
  [paths.ISSUE_TYPES]: {
    element: <IssueTypesList />,
    title: 'issue_types.list_title',
  },
  [paths.ISSUES]: {
    element: <Downtime />,
    title: 'issues.list_title',
  },
  [paths.POINT_COLLECTIONS]: {
    element: <PointCollection />,
    title: 'point_collections.list_title',
  },
  [paths.POINT_TYPES]: {
    element: <PointTypesList />,
    title: 'point_types.list_title',
  },
  [paths.EMPLOYEES_POINTS]: {
    element: <Points />,
    title: 'points.points',
  },
  [paths.PAYBOARD]: {
    element: <Payboard />,
    title: 'main_menu.payboard',
  },
  [paths.PAYBOARD_HISTORICAL]: {
    element: <PayboardHistorical />,
    title: 'main_menu.payboard_historical',
  },
  [paths.INCENTIVE]: {
    element: <Incentive />,
    title: 'main_menu.incentive',
  },
  // [paths.PAYBOARD_DETAILED]: {
  //   element: <PayboardDetailedList />,
  //   title: 'main_menu.payboard_summary_detailed',
  // },
  // [paths.PAYBOARD_HISTORICAL_DETAILED]: {
  //   element: <PayboardHistoricalDetailedList />,
  //   title: 'main_menu.payboard_historical_summary_detailed',
  // },
  // [paths.BARRETT_REPORTS]: {
  //   element: <BarretReports />,
  //   title: 'main_menu.reports.barrett_main',
  // },
  [paths.CARPARTS_REPORTS]: {
    element: <CarpartsReports />,
    title: 'main_menu.reports.carparts_main',
  },
  [paths.SONY_REPORTS]: {
    element: <SonyReports />,
    title: 'main_menu.reports.sony_main',
  },
  [paths.OPTORO_REPORTS]: {
    element: <OptoroReports />,
    title: 'main_menu.reports.optoro_main',
  },
  [paths.EXPEDITORS_REPORTS]: {
    element: <ExpeditorsReports />,
    title: 'main_menu.reports.expeditors_main',
  },
  [paths.PERRY_ELLIS_REPORTS]: {
    element: <PerryEllisReports />,
    title: 'main_menu.reports.perry_ellis_main',
  },
  [paths.EXPEDITORS_GAPS_DAILY]: {
    element: <ExpeditorsGapsDaily />,
    title: 'reports.type_labels.gaps',
  },
  [paths.PAYBOARD_WEEK_VIEW]: {
    element: <PayboardWeek />,
    title: 'payboard.week_table_name',
  },
  [paths.NEW_PAYBOARD_WEEK_VIEW]: {
    element: <NewPayboardWeek />,
    title: 'payboard.new_week_table_name',
  },
  [paths.NEW_PAYBOARD_HISTORICAL_WEEK_VIEW]: {
    element: <NewHistoricalPayboardWeek />,
    title: 'payboard.new_historical_week_table_name',
  },
  // [paths.PAYBOARD_DAILY_HOURS_SUMMARY_REPORT]: {
  //   element: <PayboardDailyHoursSummaryReport />,
  //   title: 'payboard.daily_hours_summary_table_name',
  // },
  [paths.PAYBOARD_SUMMARY_BY_WORK_WEEK]: {
    element: <PayboardSummaryByWorkWeek />,
    title: 'main_menu.payboard_summary_by_work_week',
  },
  [paths.PAYBOARD_SUMMARY_BY_WORK_WEEK_AND_SUPERVISOR]: {
    element: <PayboardSummaryByWorkAndSupervisorWeek />,
    title: 'main_menu.payboard_summary_by_work_week_and_supervisor',
  },
  [paths.CUSTOMER_REPORTS_COST_PLUS_WEEKLY]: {
    element: <CostPlusWeeklyList />,
    title: 'main_menu.customer_reports.cost_plus_weekly',
  },
  [paths.METATAGS]: {
    element: <Metatags />,
    title: 'metatags.list_title',
  },
  [paths.STANDARDS]: {
    element: <StandardsList />,
    title: 'standards.list_title',
  },
  [paths.PRICING]: {
    element: <PricingPage />,
    title: 'pricing.list_title',
  },
  // [paths.AREA_BASED_BASELINE]: {
  //   element: <AreaBasedBaselineList />,
  //   title: 'main_menu.area_based_baseline_list',
  // },
  // [paths.AREA_BASED_BASELINE_CREATE]: {
  //   element: <AreaBaseBaselineCreate />,
  //   title: 'main_menu.area_based_baseline_create',
  // },
  // [paths.AREA_BASED_BASELINE_UPDATE]: {
  //   element: <AreaBasedBaselineUpdate />,
  //   title: 'main_menu.area_based_baseline_update',
  // },
  // [paths.CPU_BASED_BASELINE]: {
  //   element: <CpuBasedBaselineList />,
  //   title: 'main_menu.cpu_based_baseline_list',
  // },
  // [paths.CPU_BASED_BASELINE_UPDATE]: {
  //   element: <CpuBasedBaselineUpdate />,
  //   title: 'main_menu.cpu_based_baseline_update',
  // },
  // [paths.CPU_BASED_BASELINE_CREATE]: {
  //   element: <CpuBaseBaselineCreate />,
  //   title: 'main_menu.cpu_based_baseline_create',
  // },
  [paths.BASELINE]: {
    element: <BaselineList />,
    title: 'main_menu.baseline_list',
  },
  [paths.BASELINE_UPDATE]: {
    element: <BaselineUpdate />,
    title: 'main_menu.baseline_update',
  },
  [paths.BASELINE_CREATE]: {
    element: <BaselineCreate />,
    title: 'main_menu.baseline_create',
  },
  [paths.RECRUITING_DASHBOARD_SUMMARY]: {
    element: <RecruitingDashboardSummary />,
    title: 'recruiting_dashboards_summary.table_name',
  },
  [paths.RECRUITING_DASHBOARD_CLOSED_POSITIONS]: {
    element: <ClosedPositionsList />,
    title: 'open_position.table_name_closed',
  },
  [paths.RECRUITING_DASHBOARD_LIST_APPLICANTS]: {
    element: <ApplicantsList />,
    title: 'applicant.table_name',
  },
  [paths.RECRUITING_DASHBOARD_CREATE_APPLICANT]: {
    element: <ApplicantsCreate />,
    title: 'applicant.create_form_card_title',
  },
  [paths.RECRUITING_DASHBOARD_UPDATE_APPLICANT]: {
    element: <ApplicantsUpdate />,
    title: 'applicant.update',
  },
  [paths.RECRUITING_DASHBOARD_VIEW_APPLICANTS]: {
    element: <ApplicantsViewList />,
    title: 'applicant.details',
  },
  [paths.RECRUITING_DASHBOARD_CREATE_OPEN_POSITION]: {
    element: <OpenPositionCreate />,
    title: 'open_position.table_name',
  },
  [paths.RECRUITING_DASHBOARD_UPDATE_OPEN_POSITION]: {
    element: <OpenPositionUpdate />,
    title: 'open_position.update',
  },
  [paths.RECRUITING_DASHBOARD_OPEN_POSITION_VIEW]: {
    element: <OpenPositionViewList />,
    title: 'open_position.details',
  },
  [paths.RECRUITING_DASHBOARD_DETAILS]: {
    element: <RecruitingDashboardDetails />,
    title: 'recruiting_dashboards_details.table_name',
  },
  [paths.POLICIES]: {
    element: <PoliciesList />,
    title: 'policies.list_title',
  },
  [paths.POLICIES_CREATE]: {
    element: <PoliciesCreate />,
    title: 'policies.create_form_title',
  },
  [paths.POLICIES_CREATE_BY_PAGE]: {
    element: <PolicesCreateFromPageSettingsConfig />,
    title: 'policies.create_form_by_page_title',
  },
  [paths.POLICIES_UPDATE]: {
    element: <PoliciesUpdate />,
    title: 'policies.bulk_update_title',
  },
  [paths.OPTITURN_CREDENTIALS]: {
    element: <OptiturnCredentialsList />,
    title: 'optiturn-credentials.list_title',
  },
  [paths.PRODUCTION_SETTINGS]: {
    element: <ProductionSettingsPage />,
    title: 'main_menu.production.production_settings.main',
  },
  [paths.SITE_TAKT_EXPORTING_SETTINGS]: {
    element: <SiteTaktExportingSettingsList />,
    title: 'main_menu.exporting_settings.takt_list',
  },
  [paths.INVOICE]: {
    element: <InvoiceList />,
    title: 'main_menu.invoices',
  },
  [paths.INVOICE_SETTINGS]: {
    element: <InvoiceSettings />,
    title: 'main_menu.invoice_settings',
  },
  // [paths.INVOICE_CREATE]: {
  //   element: <InvoicesDeprecatedCreate />,
  //   title: 'main_menu.invoice.create',
  // },
  // [paths.INVOICE_UPDATE]: {
  //   element: <InvoiceDeprecatedUpdate />,
  //   title: 'main_menu.invoice.update',
  // },
  // [paths.MONTH_INVOICE_UPDATE]: {
  //   element: <MonthInvoiceDeprecatedUpdate />,
  //   title: 'main_menu.invoice.update_month',
  // },
  [paths.INVOICE_DETAILS]: {
    element: <InvoiceWeekDetails />,
    title: 'main_menu.invoice.weekly_details',
    hideInPagesCombobox: true,
  },
  [paths.MONTH_INVOICE_DETAILS]: {
    element: <InvoiceMonthDetails />,
    title: 'main_menu.invoice.monthly_details',
    hideInPagesCombobox: true,
  },
  [paths.PRODUCTIONS]: {
    element: <ProductionsList />,
    title: 'productions.list_title',
  },
  [paths.EXECUTIVE_REPORTS]: {
    element: <ExecutiveReports />,
    title: 'main_menu.executive_reports.main',
  },
  [paths.EXECUTIVE_SUMMARY]: {
    element: <ExecutiveSummary />,
    title: 'main_menu.executive_reports.summary_config',
  },
  [paths.FINANCIAL_TRACKER_REPORTS]: {
    element: <Splash />,
    title: '',
    hideInPagesCombobox: true,
  },
  [paths.BROOKFIELD_REPORTS]: {
    element: <BrookfieldReports />,
    title: 'main_menu.reports.brookfield_main',
  },
  // [paths.CARDINAL_HEALTH_REPORTS]: {
  //   element: <CardinalHealthReports />,
  //   title: 'main_menu.reports.cardinal_health_main',
  // },
  // [paths.DORMAN_REPORTS]: {
  //   element: <DormanReports />,
  //   title: 'main_menu.reports.dorman_main',
  // },
  // [paths.LEVIS_REPORTS]: {
  //   element: <LevisReports />,
  //   title: 'main_menu.reports.levis_main',
  // },
  [paths.MCENTIRE_REPORTS]: {
    element: <McEntireReports />,
    title: 'main_menu.reports.mc-entire_main',
  },
  [paths.TARGET_REPORTS]: {
    element: <TargetReports />,
    title: 'main_menu.reports.target_main',
  },
  [paths.NFI_REPORTS]: {
    element: <NfiReports />,
    title: 'main_menu.reports.nfi_main',
  },
  [paths.WILLIAMS_SONOMA_REPORTS]: {
    element: <WilliamsSonomaReports />,
    title: 'main_menu.reports.williams_sonoma_main',
  },
  [paths.TAYLOR_FARMS_REPORTS]: {
    element: <TaylorFarmsReports />,
    title: 'main_menu.reports.taylor_farms_main',
  },
  [paths.TRAINING_VIDEOS]: {
    element: <TrainingVideos />,
    title: 'main_menu.training_videos',
  },
  [paths.CHECKLIST]: {
    element: <Checklist />,
    title: 'main_menu.checklist',
  },
  [paths.RESTORE]: {
    element: <RestoreEntities />,
    title: 'main_menu.restore.restore_entities',
  },
  [paths.PAGE_SETTING]: {
    element: <PageSettings />,
    title: 'main_menu.page_setting.title',
  },
  [paths.CHANGELOG]: {
    element: <Changelog />,
    title: 'main_menu.changelog.main',
  },
  [paths.CHANGELOG_HISTORY_EMPLOYEE]: {
    element: <HistoryEmployee />,
    title: 'main_menu.changelog.history.employee.title',
  },
  [paths.KPI_SETTINGS]: {
    element: <KpiSettings />,
    title: 'main_menu.kpi_settings',
  },
  [paths.INCENTIVE_PAYMENT_SETTINGS]: {
    element: <IncentivePaymentSettings />,
    title: 'main_menu.incentive_payment_settings',
  },
  [paths.KPI]: {
    element: <Kpi />,
    title: 'main_menu.kpi',
  },
  [paths.KPI_REPORT]: {
    element: <KpiReport />,
    title: 'main_menu.kpi_report',
  },
  [paths.DASHBOARD]: {
    element: <Dashboard />,
    title: 'main_menu.dashboard',
  },
  [paths.IP_WHITELIST]: {
    element: <IpWhitelist />,
    title: 'main_menu.ip_whitelist',
  },
};

export const privateRoutesTabs: Record<
  string,
  {
    title: string;
    hideInPagesCombobox?: boolean;
  }
> = {
  //Executive Dashboard
  [tabsPaths.EXECUTIVE_SUMMARY_EXECUTIVE_DASHBOARD_TAB]: {
    title: `${i18n.t(
      'main_menu.executive_reports.summary_config',
    )} executive tab`,
  },
  [tabsPaths.EXECUTIVE_SUMMARY_FINANCIAL_DASHBOARD_TAB]: {
    title: `${i18n.t('financial_reporting_dashboard.page_title')} v1 tab`,
  },

  [tabsPaths.TIME_KEEPING_PUNCHES_APPROVED_TAB]: {
    title: `${i18n.t('main_menu.time_keeping.time_punches')} approved tab`,
  },
  [tabsPaths.TIME_KEEPING_PUNCHES_UNAPPROVED_TAB]: {
    title: `${i18n.t('main_menu.time_keeping.time_punches')} unapproved tab`,
  },
  [tabsPaths.TIME_KEEPING_PUNCHES_MISSED_TAB]: {
    title: `${i18n.t('main_menu.time_keeping.time_punches')} missed tab`,
  },
  [tabsPaths.EMPLOYEES_POINTS_LIST_TAB]: {
    title: `${i18n.t('points.table_name.points')} tab`,
  },
  [tabsPaths.EMPLOYEES_POINTS_TOTAL_EARNED_TAB]: {
    title: `${i18n.t('points.table_name.points_summary')} tab`,
  },
  [tabsPaths.EMPLOYEES_AUTOMATIC_POINTS_TO_APPROVE_TAB]: {
    title: `${i18n.t('points.table_name.automatic_points_to_approve')} tab`,
  },
  [tabsPaths.EMPLOYEE_QUESTIONS_TAB]: {
    title: `${i18n.t('main_menu.employees_questions')} tab`,
  },
  [tabsPaths.EMPLOYEE_QUESTIONS_CATEGORIES_TAB]: {
    title: `${i18n.t('main_menu.employees_question_categories')} tab`,
  },
  [tabsPaths.EMPLOYEE_QUESTIONS_CATEGORIES_TYPES_TAB]: {
    title: `${i18n.t('question_category_type.table_name')} tab`,
  },
  [tabsPaths.EMPLOYEES_REVIEWS]: {
    title: `Employee Reviews tab`,
  },
  [tabsPaths.EMPLOYEES_POINTS_REVIEWS]: {
    title: `Employee Points Reviews tab`,
  },
  [tabsPaths.EMPLOYEES_OVERDUE_REVIEWS]: {
    title: `Employee Overdue Reviews tab`,
  },
  // Daily Hrs reports
  [tabsPaths.TIME_KEEPING_DAILY_HRS_TAB]: {
    title: `${i18n.t('main_menu.chart_reports.daily_hours')} ${i18n.t(
      'main_menu.chart_reports.daily_hours',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_IN_TAB]: {
    title: `${i18n.t('main_menu.chart_reports.daily_hours')} ${i18n.t(
      'main_menu.chart_reports.daily_hours_dashboard_in',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_TAB]: {
    title: `${i18n.t('main_menu.chart_reports.daily_hours')} ${i18n.t(
      'main_menu.chart_reports.daily_hours_dashboard',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_DAILY_HRS_DASHBOARD_CHARTS_TAB]: {
    title: `${i18n.t('main_menu.chart_reports.daily_hours')} ${i18n.t(
      'main_menu.chart_reports.daily_hours_charts',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_INSERTED_PUNCHES_COUNT_TAB]: {
    title: `Inserted Punches Count tab`,
  },
  // This one also related to daily hrs
  [tabsPaths.TIME_KEEPING_PAYBOARD_BY_WEEK_SUPERVISOR_TAB]: {
    title: `${i18n.t(
      'main_menu.payboard_summary_by_work_week_and_supervisor',
    )} tab`,
  },
  // Time keeping payboard
  [tabsPaths.TIME_KEEPING_PAYBOARD_SUMMARY_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t('payboard.summary')} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_DETAILED_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t('payboard.detailed')} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_BY_WEEK_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t('payboard.by_week')} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_BY_DAY_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t('payboard.by_day')} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_KRONOS_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t('payboard.kronos')} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_SUMMARY_BY_WORK_WEEK_TAB]: {
    title: `${i18n.t('main_menu.payboard')} ${i18n.t(
      'payboard.summary_by_work_week.title',
    )} tab`,
  },

  [tabsPaths.TIME_KEEPING_PAYBOARD_HISTORICAL_TAB]: {
    title: `${i18n.t('main_menu.payboard_historical')} ${i18n.t(
      'payboard.summary_historical',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_DETAILED_HISTORICAL_TAB]: {
    title: `${i18n.t('main_menu.payboard_historical')} ${i18n.t(
      'payboard.detailed_historical',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_PAYBOARD_KRONOS_HISTORICAL_TAB]: {
    title: `${i18n.t('main_menu.payboard_historical')} ${i18n.t(
      'payboard.kronos_historical',
    )} tab`,
  },

  //incentive tabs
  [tabsPaths.TIME_KEEPING_INCENTIVE_INCENTIVE_PAYMENT_TAB]: {
    title: `${i18n.t('main_menu.incentive')} ${i18n.t(
      'payboard.incentive_payment.title',
    )} tab`,
  },
  [tabsPaths.TIME_KEEPING_INCENTIVE_INCENTIVE_DETAILED_PAYMENT_TAB]: {
    title: `${i18n.t('main_menu.incentive')} ${i18n.t(
      'payboard.incentive_detailed_payment.title',
    )} tab`,
  },

  [tabsPaths.DASHBOARD_FINANCIAL_REPORT_TAB]: {
    title: `${i18n.t('financial_reporting_dashboard.page_title')} tab`,
  },
  [tabsPaths.DASHBOARD_HR_DASHBOARD_TAB]: {
    title: `${i18n.t('dashboard.hr_dashboard')} tab`,
  },
  [tabsPaths.DASHBOARD_HR_CHANGES_TAB]: {
    title: `${i18n.t('dashboard.hr_changes')} tab`,
  },
  [tabsPaths.DASHBOARD_RECRUITING_TAB]: {
    title: `${i18n.t('dashboard.recruiting')} tab`,
  },
  [tabsPaths.DASHBOARD_TURNOVER_BY_REASON_TAB]: {
    title: `${i18n.t('dashboard.turnover_by_reason')} tab`,
  },

  // absentee tabs
  [tabsPaths.ABSENTEE_REALTIME_TAB]: {
    title: `${i18n.t('main_menu.absentee')} ${i18n.t(
      'main_menu.employees_hooky_folks',
    )} tab`,
  },
  [tabsPaths.ABSENTEE_HISTORICAL_TAB]: {
    title: `${i18n.t('main_menu.absentee')} ${i18n.t(
      'main_menu.employees_historical_hooky_folks',
    )} tab`,
  },

  // Costs tabs
  [tabsPaths.COSTS_BY_DEPARTMENT]: {
    title: `${i18n.t('main_menu.payboard_costs.main_item')} ${i18n.t(
      'main_menu.payboard_costs.costs_by_department',
    )} tab`,
  },
  [tabsPaths.COSTS_BY_DAY]: {
    title: `${i18n.t('main_menu.payboard_costs.main_item')} ${i18n.t(
      'main_menu.payboard_costs.costs_by_day',
    )} tab`,
  },
  [tabsPaths.COSTS_BY_DAY_AND_DEPARTMENT]: {
    title: `${i18n.t('main_menu.payboard_costs.main_item')} ${i18n.t(
      'main_menu.payboard_costs.costs_by_day_and_department',
    )} tab`,
  },

  // Turnover tabs
  [tabsPaths.TURNOVER_DASHBOARD_TAB]: {
    title: `${i18n.t('main_menu.turnover')} ${i18n.t(
      'main_menu.turnover_dashbord',
    )} tab`,
  },
  [tabsPaths.TURNOVER_BY_REASON_TAB]: {
    title: `${i18n.t('main_menu.turnover')} ${i18n.t(
      'main_menu.turnover_by_reason_details',
    )} tab`,
  },

  // Invoice tabs
  [tabsPaths.INVOICE_WEEKLY_TAB]: {
    title: `${i18n.t('main_menu.invoice.main_item')} Weekly tab`,
  },
  [tabsPaths.INVOICE_MONTHLY_TAB]: {
    title: `${i18n.t('main_menu.invoice.main_item')} Monthly tab`,
  },
  [tabsPaths.INVOICE_DETAILS_FAKE_TAB]: {
    title: `${i18n.t('main_menu.invoice.main_item')} Weekly Item Details`,
  },
  [tabsPaths.MONTH_INVOICE_DETAILS_FAKE_TAB]: {
    title: `${i18n.t('main_menu.invoice.main_item')} Monthly Item Details`,
  },

  // Invoice settings tabs
  [tabsPaths.INVOICE_SETTINGS_ADDITIONAL_FEE]: {
    title: 'Invoice settings Additional Fee tab',
  },
  [tabsPaths.INVOICE_SETTINGS_ADDITIONAL_FEE_CATEGORY]: {
    title: 'Invoice settings Additional Fee Category tab',
  },
  [tabsPaths.INVOICE_SETTINGS_HOURLY_SETTINGS]: {
    title: 'Invoice settings Hourly Settings tab',
  },
  [tabsPaths.INVOICE_SETTINGS_NGROUP_BILLING_DETAILS]: {
    title: 'Invoice settings Ngroup Billing Details tab',
  },
  [tabsPaths.INVOICE_SETTINGS_SITE_BILLING_DETAILS]: {
    title: 'Invoice settings Site Billing Details tab',
  },
  [tabsPaths.INVOICE_SETTINGS_OTHER_COSTS]: {
    title: 'Invoice settings Other Costs tab',
  },

  // Site
  [tabsPaths.SITES_TAB]: {
    title: 'Sites tab',
  },
  [tabsPaths.ARCHIVED_SITES_TAB]: {
    title: 'Archived Sites tab',
  },
  [tabsPaths.IMPORTING_SETTINGS_TAB]: {
    title: 'Importing Settings tab',
  },

  // Shift
  [tabsPaths.SHIFT_TAB]: {
    title: 'Shifts tab',
  },
  [tabsPaths.SHIFT_WORKDAYS_TAB]: {
    title: 'Shift Workdays tab',
  },

  // Production settings
  [tabsPaths.PRODUCTION_SETTINGS_TAB]: {
    title: 'Production settings tab',
  },
  [tabsPaths.PRODUCTION_SETTINGS_BOMS_TAB]: {
    title: 'Production settings boms tab',
  },
};
