import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { DailyUphBySiteAndEmployeeDetails } from './DailyUphBySiteAndEmployeeDetails';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IExpeditorsDailyUphBySiteAndEmployeeProps {
  date?: string;
  siteId?: number;
  employeeId?: number;
}

const dailyUphBySiteAndEmployeeUrl =
  '/expeditors/daily-uph/by-site-and-employee';

const defaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'date',
};

const ExpeditorsDailyUphBySiteAndEmployee = ({
  date,
  siteId,
  employeeId,
}: IExpeditorsDailyUphBySiteAndEmployeeProps) => {
  const { t } = useTranslation();
  // to fetch data for the specific site only
  const isSiteSelected = Boolean(siteId);

  const [
    employeeNameToViewDetails,
    setEmployeeNameToViewDetails,
  ] = React.useState<string | null>(null);

  const where = React.useMemo(
    () => ({
      date: date ?? undefined,
      siteId: siteId ?? undefined,
      employeeId: employeeId ?? undefined,
    }),
    [date, employeeId, siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(dailyUphBySiteAndEmployeeUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${dailyUphBySiteAndEmployeeUrl}/count`,
    {
      count: 0,
    },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('productions_uph_reports.date'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'employee',
        orderConfig: { orderBy: 'employee' },
        label: t('productions_uph_reports.employee'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'hrs',
        orderConfig: { orderBy: 'hrs' },
        label: t('productions_uph_reports.hrs'),
      },
      {
        id: 'uph',
        orderConfig: { orderBy: 'uph' },
        label: t('productions_uph_reports.uph'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  React.useEffect(() => {
    if (siteId) {
      fetchData({
        filter: {
          where,
          limit,
          offset: page,
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
        },
      });

      fetchCountData({ filter: { where } });
    }
  }, [fetchCountData, fetchData, limit, order, orderBy, page, siteId, where]);

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && !employeeNameToViewDetails && (
        <>
          <ReportTable
            data={data.items as any}
            count={countData.count}
            summaryData={data.summary}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={dailyUphBySiteAndEmployeeUrl}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
            onTableRowClick={(_, employeeData) => {
              setEmployeeNameToViewDetails((employeeData as any).employee);
            }}
          />
        </>
      )}
      <DailyUphBySiteAndEmployeeDetails
        siteId={siteId}
        headCells={headCells}
        employeeName={employeeNameToViewDetails}
        onBackClick={() => setEmployeeNameToViewDetails(null)}
      />
    </>
  );
};

export default ExpeditorsDailyUphBySiteAndEmployee;
