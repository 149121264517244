import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICommonFilterProps } from 'src/components/Table/components/TableActions/components/Filter/types';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { useFetchShiftsCombobox } from 'src/modules/utils';

type IShiftsComboBoxFilterProps = Omit<
  IComboboxProps,
  'value' | 'options' | 'name'
> &
  ICommonFilterProps & {
    propertyAsId?: 'id' | 'shiftName';
  };

export const ShiftsComboBoxFilter: React.FC<IShiftsComboBoxFilterProps> = ({
  value,
  name,
  propertyAsId = 'id',
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchShiftsCombobox = useFetchShiftsCombobox();

  const options = useSelector(getShiftOptionsByGloballySites);
  const optionsWithProperId =
    propertyAsId === 'id'
      ? options
      : options.map((item) => ({ ...item, id: item[propertyAsId] }));

  useEffect(() => {
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueId = getFilterByName(name) ?? value;

  const parsedValueId = propertyAsId === 'id' ? Number(valueId) : valueId;

  console.log('options =>', options);
  console.log('optionsWithProperId =>', optionsWithProperId);

  const comboBoxValue =
    (optionsWithProperId as ComboBoxOption[]).find(
      (option) => option.id === parsedValueId,
    ) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue as ComboBoxOption}
      options={optionsWithProperId as ComboBoxOption[]}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
