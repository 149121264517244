import React from 'react';
import FormControl from '@mui/joy/FormControl';
import Input, { InputProps } from '@mui/joy/Input';
import FormLabel, { FormLabelProps } from '@mui/joy/FormLabel';
import FormHelperText, { FormHelperTextProps } from '@mui/joy/FormHelperText';
import { Typography, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/joy/styles/types';

export interface ITextFieldProps extends Omit<InputProps, 'ref'> {
  label?: string;
  helperText?: string;
  helperTextProps?: Omit<FormHelperTextProps, 'children'>;
  labelProps?: Omit<FormLabelProps, 'label'>;
  step?: number | string | 'any';
  formControlSx?: SxProps;
}

export const TextField = React.forwardRef<
  HTMLInputElement | null,
  ITextFieldProps
>(
  (
    {
      label,
      error,
      labelProps,
      helperText,
      helperTextProps,
      placeholder,
      slotProps,
      step,
      formControlSx,
      ...inputProps
    },
    ref,
  ) => {
    const theme = useTheme();

    const { t } = useTranslation();

    return (
      <FormControl error={error} sx={formControlSx}>
        {label && (
          <FormLabel {...labelProps}>
            <Typography
              level="text_sm"
              fontWeight="medium"
              textColor="colors.text.text_secondary.main"
            >
              {label}
            </Typography>
          </FormLabel>
        )}
        <Input
          sx={{
            '&::placeholder': {
              fontWeight: theme.fontWeight.regular,
              color: theme.palette.colors.text.text_placeholder,
              ...theme.typography.text_md,
            },
          }}
          slotProps={{
            ...slotProps,
            input: {
              step,
              ...slotProps?.input,
              ref,
            },
          }}
          {...inputProps}
          placeholder={placeholder ?? t('common.add_your_text_here')}
        />
        {helperText && (
          <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  },
);
