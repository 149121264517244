import { get, map, memoize, omit, reduce, flatten } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  ISiteModel,
  IStoreState,
  IUpdateShift,
  ShiftComboboxOption,
} from '../types';
import { filterListBySiteId } from '../utils/helpers/common';
import { getGloballySelectedSites } from './site';

export const getShiftRefreshKey = (state: IStoreState) =>
  state.shift.refreshKey;

export const getShiftData = (state: IStoreState) => state.shift;

/**
 * Get server error
 * @param shift - State shift
 */
export const getServerError = ({ shift }: IStoreState) => shift.error;

/**
 * Get shift list
 * @param shift - State shift
 */
export const getShiftList = ({ shift }: IStoreState) =>
  map(shift.list, (item) => ({
    ...item,
    overnight: !!item.overnight,
    default: !!item.default,
  }));

/**
 * Get shifts by array of ids
 * @param shift - State shift
 */
export const getShiftsByIds = createSelector(
  getShiftList,
  memoize((shifts) => (ids: IdsArray) => {
    const initial: IUpdateShift[] = [];
    return reduce(
      shifts,
      (acc, cur) => {
        const lunch = get(cur.site, 'lunchAdj');
        if (ids.includes(`${cur.id}`)) {
          const data = {
            ...omit(cur, ['site', 'duration']),
            lunchAdj: cur.lunchAdj,
            lunchMin: cur.lunchMin,
          };
          const noLunchData = omit(cur, [
            'site',
            'duration',
            'lunchAdj',
            'lunchMin',
          ]);
          const parsedData = lunch ? data : noLunchData;
          acc.push(parsedData as IUpdateShift);
        }
        return acc;
      },
      initial,
    );
  }),
);

/**
 * Get shift count
 * @param shift - State shift
 */
export const getShiftCount = ({ shift }: IStoreState) => shift.count;

/**
 * Get combobox list
 */
export const getShiftsComboboxList = ({ shift }: IStoreState) =>
  shift.comboboxList.map((item) => ({
    ...item,
    name: `${item.name} (${item.site?.name})`,
    shiftName: item.name,
  })) as (ShiftComboboxOption & { shiftName: string })[];

/**
 * Get shifts combobox list as map where key is id and value is ShiftComboboxOption
 */
export const getShiftsComboboxMap = createSelector(
  getShiftsComboboxList,
  (shifts): Record<number, ShiftComboboxOption> =>
    Object.fromEntries(shifts.map((s) => [s.id, s])),
);

/**
 * Get shifts related to passed siteId
 * Or nothing if siteId is NaN
 * @param siteId
 * @return function that can be used as selector
 */
export const getShiftsComboboxListBySiteId = createSelector(
  getShiftsComboboxList,
  (shifts) => memoize((siteId: number) => filterListBySiteId(shifts, siteId)),
);

export const getShiftsComboboxListBySites = createSelector(
  getShiftsComboboxList,
  (shifts) =>
    memoize((sites: number[]) =>
      flatten(map(sites, (site) => filterListBySiteId(shifts, site))),
    ),
);

export const getShiftOptionsByGloballySites = createSelector(
  getShiftsComboboxList,
  getGloballySelectedSites,
  (shifts, selectedSites) => {
    const shiftOptions = [];

    // fill shift's combobox list by globally selected sites
    for (const shift of shifts as (ShiftComboboxOption & {
      shiftName: string;
      site: ISiteModel;
    })[]) {
      if (shift.site?.id && selectedSites.includes(shift.site?.id)) {
        shiftOptions.push({
          id: shift.id,
          name: shift.name,
          siteId: shift.siteId,
          site: shift.site,
          shiftName: shift.shiftName,
        });
      }
    }

    return shiftOptions;
  },
);
