import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComposeFiltersProps } from 'src/modules/utils';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import {
  Table,
  TableActions,
  TableContent,
  TableToolbar,
} from 'src/components/Table';
import { ClientKey } from '../../../modules/constants';
import { EnhancedTableExport } from 'src/components/EnhancedTable/EnhancedTableExport';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { Box, Typography } from '@mui/joy';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IScorecardReportProps {
  tableName: string;
  data: any;
  headCells: any;
  siteId: number;
  week: number;
  year: number;
  isDataLoading: boolean;
  exportProps: {
    props: ReturnType<typeof useReportExportProps>;
    filters: ComposeFiltersProps;
  };

  clientKey: ClientKey;
}

export const ScorecardReport = ({
  tableName,
  year,
  week,
  siteId,
  data,
  headCells,
  exportProps,
  isDataLoading,
}: React.PropsWithChildren<IScorecardReportProps>) => {
  const { t } = useTranslation();

  const [isExportVisible, setIsExportVisible] = React.useState(false);

  const isAllFiltersSelected = year && week && siteId;

  return (
    <>
      <Table
        tableUniqueKey={tableName}
        sx={{
          height: isDataLoading || !data.length ? '100%' : 'auto',
        }}
      >
        <TableToolbar>
          <TableActions>
            <EnhancedTableExport
              isOpen={isExportVisible}
              onClose={() => setIsExportVisible(false)}
              {...exportProps.props}
              composeFilters={exportProps.filters}
            />
          </TableActions>
        </TableToolbar>
        {isDataLoading && <MainContentLoader />}

        {isAllFiltersSelected ? (
          <TableContent
            data={data}
            headCells={headCells}
            isDataLoading={isDataLoading}
          />
        ) : (
          <RequiredFiltersAlert i18nKey="mc-entire_reports.select_filters_message" />
        )}
        <Box sx={{ mr: '8px', maxHeight: '16px' }}>
          <Typography
            style={{
              position: 'relative',
              top: '-8px',
              fontStyle: 'italic',
              textAlign: 'end',
              marginTop: 8,
              fontSize: '12px',
            }}
          >
            * {t('common.report_does_not_include_overtime_hours')}
          </Typography>
        </Box>
      </Table>
    </>
  );
};
