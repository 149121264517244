import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IEmployeeModel,
  IEmployeesActiveButNotPresentModel,
  IEmployeeState,
  IEmployeesTerminatedModel,
  IHistoricalHookyFolksModel,
  IHookyFolksModel,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IEmployeeState = {
  refreshKey: 0,
  id: NaN,
  employeeId: '',
  siteId: NaN,
  positionId: NaN,
  staffingId: NaN,
  supervisorId: NaN,
  firstName: '',
  lastName: '',
  payRate: NaN,
  dateHired: null,
  badge: NaN,
  defaultShiftId: NaN,
  defaultDepartmentId: NaN,
  list: [],
  removedList: [],
  comboboxList: [],
  count: 0,
  removedCount: 0,
  activeButNotPresent: {
    list: [],
    count: 0,
  },
  terminated: {
    list: [],
    count: 0,
  },
  hookyFolks: {
    list: [],
    count: 0,
  },
  historicalHookyFolks: {
    list: [],
    count: 0,
  },
  isTableDataRequestInProgress: false,
  isTableDataCountRequestInProgress: false,
  isEmployeesComboboxListRequestInProgress: false,
};

/**
 * Flush actions
 */
const flushEmployeeState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createEmployeeRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createEmployeeSuccess = (
  state: IEmployeeState,
  payload: IEmployeeModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createEmployeeFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getEmployeeListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isTableDataRequestInProgress: true,
});

const getEmployeeListSuccess = (
  state: IEmployeeState,
  payload: IEmployeeModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  isTableDataRequestInProgress: false,
});

const getEmployeeListFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isTableDataRequestInProgress: false,
});

/**
 * Get removed list actions
 */
const getRemovedEmployeeListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRemovedEmployeeListSuccess = (
  state: IEmployeeState,
  payload: IEmployeeModel[],
) => ({
  ...state,
  removedList: payload,
  error: false,
  is_data_requested: false,
});

const getRemovedEmployeeListFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list of active but not present actions
 */
const getEmployeeActiveButNotPresentListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getEmployeeActiveButNotPresentListSuccess = (
  state: IEmployeeState,
  payload: IEmployeesActiveButNotPresentModel[],
) => ({
  ...state,
  activeButNotPresent: {
    ...state.activeButNotPresent,
    list: payload,
  },
  error: false,
  is_data_requested: false,
});

const getEmployeeActiveButNotPresentListFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getEmployeeActiveButNotPresentCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getEmployeeActiveButNotPresentCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  activeButNotPresent: {
    ...state.activeButNotPresent,
    ...payload,
  },
  error: false,
  is_data_requested: false,
});

const getEmployeeActiveButNotPresentCountFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list of terminated employees actions
 */
const getEmployeeTerminatedListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getEmployeeTerminatedListSuccess = (
  state: IEmployeeState,
  payload: IEmployeesTerminatedModel[],
) => ({
  ...state,
  terminated: {
    ...state.terminated,
    list: payload,
  },
  error: false,
  is_data_requested: false,
});
const getEmployeeTerminatedListFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count of terminated employees actions
 */
const getEmployeeTerminatedCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getEmployeeTerminatedCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  terminated: {
    ...state.terminated,
    ...payload,
  },
  error: false,
  is_data_requested: false,
});

const getEmployeeTerminatedCountFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list of terminated employees actions
 */
const getHookyFolksListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getHookyFolksListSuccess = (
  state: IEmployeeState,
  payload: IHookyFolksModel[],
) => ({
  ...state,
  hookyFolks: {
    ...state.hookyFolks,
    list: payload,
  },
  error: false,
  is_data_requested: false,
});
const getHookyFolksListFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count of hookyFolks employees actions
 */
const getHookyFolksCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getHookyFolksCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  hookyFolks: {
    ...state.hookyFolks,
    ...payload,
  },
  error: false,
  is_data_requested: false,
});

const getHookyFolksCountFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});
/**
 * Get list of terminated employees actions
 */
const getHistoricalHookyFolksListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getHistoricalHookyFolksListSuccess = (
  state: IEmployeeState,
  payload: IHistoricalHookyFolksModel[],
) => ({
  ...state,
  historicalHookyFolks: {
    ...state.historicalHookyFolks,
    list: payload,
  },
  error: false,
  is_data_requested: false,
});
const getHistoricalHookyFolksListFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count of hookyFolks employees actions
 */
const getHistoricalHookyFolksCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getHistoricalHookyFolksCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  historicalHookyFolks: {
    ...state.historicalHookyFolks,
    ...payload,
  },
  error: false,
  is_data_requested: false,
});

const getHistoricalHookyFolksCountFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getEmployeeCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isTableDataCountRequestInProgress: true,
});

const getEmployeeCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  isTableDataCountRequestInProgress: false,
});

const getEmployeeCountFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isTableDataCountRequestInProgress: false,
});

/**
 * Get count actions
 */
const getRemovedEmployeeCountRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRemovedEmployeeCountSuccess = (
  state: IEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  removedCount: payload.count,
  error: false,
  is_data_requested: false,
});

const getRemovedEmployeeCountFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteEmployeeRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteEmployeeFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateEmployeesRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateEmployeesFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getEmployeeComboboxListRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isEmployeesComboboxListRequestInProgress: true,
});

const getEmployeeComboboxListSuccess = (
  state: IEmployeeState,
  payload: Partial<IEmployeeModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
  isEmployeesComboboxListRequestInProgress: false,
});

const getEmployeeComboboxListFailed = (
  state: IEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isEmployeesComboboxListRequestInProgress: false,
});
/**
 * Get restore list actions
 */
const restoreEmployeeRequest = (state: IEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const restoreEmployeeSuccess = (state: IEmployeeState, employeeId: number) => ({
  ...state,
  error: false,
  removedList: state.removedList.filter(({ id }) => id !== employeeId),
  removedCount: state.removedCount - 1,
  is_data_requested: false,
});

const restoreEmployeeFailed = (state: IEmployeeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */
const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeState>();

const exportEmployeeRequest = exportRequest;
const exportEmployeeSuccess = exportSuccess;
const exportEmployeeFailed = exportFailed;

/**
 * Export for active but not present employees actions
 * @param state
 */
const {
  exportRequest: exportEmployeeActiveButNotPresentRequest,
  exportFailed: exportEmployeeActiveButNotPresentFailed,
  exportSuccess: exportEmployeeActiveButNotPresentSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeState>();

/**
 * Export for terminated employees actions
 * @param state
 */
const {
  exportRequest: exportEmployeeTerminatedRequest,
  exportFailed: exportEmployeeTerminatedFailed,
  exportSuccess: exportEmployeeTerminatedSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeState>();

const {
  exportRequest: exportHookyFolksRequest,
  exportFailed: exportHookyFolksFailed,
  exportSuccess: exportHookyFolksSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeState>();

const {
  exportRequest: exportHistoricalHookyFolksRequest,
  exportFailed: exportHistoricalHookyFolksFailed,
  exportSuccess: exportHistoricalHookyFolksSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeState>();

/**
 * Employee reducer
 */
export const employee = createReducer<IEmployeeState>({}, defaultState)
  // flush actions
  .on(actions.flushEmployeeState, flushEmployeeState)
  // creation actions
  .on(actions.createEmployeeRequest, createEmployeeRequest)
  .on(actions.createEmployeeSuccess, createEmployeeSuccess)
  .on(actions.createEmployeeFailed, createEmployeeFailed)
  // get list actions
  .on(actions.getEmployeeListRequest, getEmployeeListRequest)
  .on(actions.getEmployeeListSuccess, getEmployeeListSuccess)
  .on(actions.getEmployeeListFailed, getEmployeeListFailed)
  // get removed list actions
  .on(actions.getRemovedEmployeeListRequest, getRemovedEmployeeListRequest)
  .on(actions.getRemovedEmployeeListSuccess, getRemovedEmployeeListSuccess)
  .on(actions.getRemovedEmployeeListFailed, getRemovedEmployeeListFailed)
  // get count actions
  .on(actions.getEmployeeCountRequest, getEmployeeCountRequest)
  .on(actions.getEmployeeCountSuccess, getEmployeeCountSuccess)
  .on(actions.getEmployeeCountFailed, getEmployeeCountFailed)
  // get removed count actions
  .on(actions.getRemovedEmployeeCountRequest, getRemovedEmployeeCountRequest)
  .on(actions.getRemovedEmployeeCountSuccess, getRemovedEmployeeCountSuccess)
  .on(actions.getRemovedEmployeeCountFailed, getRemovedEmployeeCountFailed)
  // deletion actions
  .on(actions.deleteEmployeeRequest, deleteEmployeeRequest)
  .on(actions.deleteEmployeeFailed, deleteEmployeeFailed)
  // updating actions
  .on(actions.updateEmployeesRequest, updateEmployeesRequest)
  .on(actions.updateEmployeesFailed, updateEmployeesFailed)
  // get combobox list actions
  .on(actions.getEmployeeComboboxListRequest, getEmployeeComboboxListRequest)
  .on(actions.getEmployeeComboboxListSuccess, getEmployeeComboboxListSuccess)
  .on(actions.getEmployeeComboboxListFailed, getEmployeeComboboxListFailed)
  // export actions
  .on(actions.exportEmployeeRequest, exportEmployeeRequest)
  .on(actions.exportEmployeeSuccess, exportEmployeeSuccess)
  .on(actions.exportEmployeeFailed, exportEmployeeFailed)
  // get list of active but not present employees actions
  .on(
    actions.getEmployeeActiveButNotPresentListRequest,
    getEmployeeActiveButNotPresentListRequest,
  )
  .on(
    actions.getEmployeeActiveButNotPresentListSuccess,
    getEmployeeActiveButNotPresentListSuccess,
  )
  .on(
    actions.getEmployeeActiveButNotPresentListFailed,
    getEmployeeActiveButNotPresentListFailed,
  )
  // get count of active but not present employees actions
  .on(
    actions.getEmployeeActiveButNotPresentCountRequest,
    getEmployeeActiveButNotPresentCountRequest,
  )
  .on(
    actions.getEmployeeActiveButNotPresentCountSuccess,
    getEmployeeActiveButNotPresentCountSuccess,
  )
  .on(
    actions.getEmployeeActiveButNotPresentCountFailed,
    getEmployeeActiveButNotPresentCountFailed,
  )
  // export of active but not present employees actions
  .on(
    actions.exportEmployeeActiveButNotPresentRequest,
    exportEmployeeActiveButNotPresentRequest,
  )
  .on(
    actions.exportEmployeeActiveButNotPresentSuccess,
    exportEmployeeActiveButNotPresentSuccess,
  )
  .on(
    actions.exportEmployeeActiveButNotPresentFailed,
    exportEmployeeActiveButNotPresentFailed,
  )
  // get list of terminated employees actions
  .on(
    actions.getEmployeeTerminatedListRequest,
    getEmployeeTerminatedListRequest,
  )
  .on(
    actions.getEmployeeTerminatedListSuccess,
    getEmployeeTerminatedListSuccess,
  )
  .on(actions.getEmployeeTerminatedListFailed, getEmployeeTerminatedListFailed)
  // get count of terminated employees actions
  .on(
    actions.getEmployeeTerminatedCountRequest,
    getEmployeeTerminatedCountRequest,
  )
  .on(
    actions.getEmployeeTerminatedCountSuccess,
    getEmployeeTerminatedCountSuccess,
  )
  .on(
    actions.getEmployeeTerminatedCountFailed,
    getEmployeeTerminatedCountFailed,
  )
  // get list of terminated employees actions
  .on(actions.getHookyFolksListRequest, getHookyFolksListRequest)
  .on(actions.getHookyFolksListSuccess, getHookyFolksListSuccess)
  .on(actions.getHookyFolksListFailed, getHookyFolksListFailed)
  // get count of terminated employees actions
  .on(actions.getHookyFolksCountRequest, getHookyFolksCountRequest)
  .on(actions.getHookyFolksCountSuccess, getHookyFolksCountSuccess)
  .on(actions.getHookyFolksCountFailed, getHookyFolksCountFailed)
  // export of terminated employees actions
  .on(actions.exportEmployeeTerminatedRequest, exportEmployeeTerminatedRequest)
  .on(actions.exportEmployeeTerminatedSuccess, exportEmployeeTerminatedSuccess)
  .on(actions.exportEmployeeTerminatedFailed, exportEmployeeTerminatedFailed)
  .on(actions.exportHookyFolksRequest, exportHookyFolksRequest)
  .on(actions.exportHookyFolksSuccess, exportHookyFolksSuccess)
  .on(actions.exportHookyFolksFailed, exportHookyFolksFailed)

  .on(
    actions.getHistoricalHookyFolksListRequest,
    getHistoricalHookyFolksListRequest,
  )
  .on(
    actions.getHistoricalHookyFolksListSuccess,
    getHistoricalHookyFolksListSuccess,
  )
  .on(
    actions.getHistoricalHookyFolksListFailed,
    getHistoricalHookyFolksListFailed,
  )

  .on(
    actions.getHistoricalHookyFolksCountRequest,
    getHistoricalHookyFolksCountRequest,
  )
  .on(
    actions.getHistoricalHookyFolksCountSuccess,
    getHistoricalHookyFolksCountSuccess,
  )
  .on(
    actions.getHistoricalHookyFolksCountFailed,
    getHistoricalHookyFolksCountFailed,
  )

  .on(actions.exportEmployeeTerminatedRequest, exportEmployeeTerminatedRequest)
  .on(actions.exportEmployeeTerminatedSuccess, exportEmployeeTerminatedSuccess)
  .on(actions.exportEmployeeTerminatedFailed, exportEmployeeTerminatedFailed)
  .on(
    actions.exportHistoricalHookyFolksRequest,
    exportHistoricalHookyFolksRequest,
  )
  .on(
    actions.exportHistoricalHookyFolksSuccess,
    exportHistoricalHookyFolksSuccess,
  )
  .on(
    actions.exportHistoricalHookyFolksFailed,
    exportHistoricalHookyFolksFailed,
  )
  // restore employee actions
  .on(actions.restoreEmployeeRequest, restoreEmployeeRequest)
  .on(actions.restoreEmployeeSuccess, restoreEmployeeSuccess)
  .on(actions.restoreEmployeeFailed, restoreEmployeeFailed);

export default employee;
