import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from 'src/config';
import {
  AnyObject,
  DataObjectWithCount,
  IListWithSummary,
} from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IProps {
  siteId?: number;
  category?: string;
  year?: number;
  week?: number;
  employeeId?: number;
  supervisorId?: number;
}

export const NfiIncentiveIndividual: React.FC<IProps> = ({
  siteId,
  week,
  year,
  category,
  employeeId,
  supervisorId,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'asc' as const,
    orderBy: 'siteId',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      year,
      week,
      employeeId,
      supervisorId,
      incentiveArea: category,
    }),
    [siteId, year, week, category, employeeId, supervisorId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    DataObjectWithCount<IListWithSummary<AnyObject>>
  >(paths.NFI_WEEKLY_INCENTIVE_INDIVIDUAL, {
    data: {
      items: [],
      summary: [],
    },
    count: 0,
  });
  const computedItems = React.useMemo(
    () =>
      data.data.items.map((item) => ({
        ...item,
      })),
    [data.data.items],
  );

  const showData = Boolean(siteId && year && week);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'incentiveArea',
        label: t('payboard.incentive.area'),
        orderConfig: { orderBy: 'incentiveArea' },
      },
      {
        id: 'extEmployeeId',
        label: t('payboard.extEmployeeId'),
        orderConfig: { orderBy: 'extEmployeeId' },
      },
      {
        id: 'employeeId',
        label: t('employees.emp_id'),
        orderConfig: { orderBy: 'employeeId' },
      },
      {
        id: 'wmsLogin',
        label: t('users.wmsLogin'),
        orderConfig: { orderBy: 'wmsLogin' },
      },
      {
        id: 'payRate',
        label: t('payboard.payrate'),
        orderConfig: { orderBy: 'payRate' },
      },
      {
        id: 'firstName',
        label: t('employees.first_name'),
        orderConfig: { orderBy: 'firstName' },
      },
      {
        id: 'lastName',
        label: t('employees.last_name'),
        orderConfig: { orderBy: 'lastName' },
      },
      {
        id: 'staffingProvider',
        label: t('payboard.staffing_provider'),
        orderConfig: { orderBy: 'staffingProvider' },
      },
      {
        id: 'site',
        label: t('payboard.site_name'),
        orderConfig: { orderBy: 'site' },
      },
      {
        id: 'siteId',
        label: t('payboard.site_id'),
        orderConfig: { orderBy: 'siteId' },
      },
      {
        id: 'client',
        label: t('users.client_name'),
        orderConfig: { orderBy: 'client' },
      },
      {
        id: 'year',
        label: t('payboard.year'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'week',
        label: t('payboard.week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'yearWeek',
        label: t('payboard.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'sumTimeTotal',
        label: t('payboard.time_total'),
        orderConfig: { orderBy: 'sumTimeTotal' },
      },
      {
        id: 'units',
        label: t('payboard.units'),
        orderConfig: { orderBy: 'units' },
      },
      {
        id: 'uph',
        label: t('productions_uph_reports.uph'),
        orderConfig: { orderBy: 'uph' },
      },
      {
        id: 'incentivePrice',
        label: t('pricing.incentivePrice'),
        orderConfig: { orderBy: 'incentivePrice' },
      },
      {
        id: 'incentivePay',
        label: t('payboard.total_pay'),
        orderConfig: { orderBy: 'incentivePay' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });
  // Perry Ellis Daily Uph By Area

  React.useEffect(() => {
    if (showData) {
      fetchData({
        filter: {
          where,
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
        },
      });
    }
  }, [fetchData, order, orderBy, where, showData]);

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        where,
        order: [`${orderBy} ${order}`],
      }),
      [order, orderBy, where],
    ),
    localization: React.useMemo(
      () =>
        data.data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.data.summary, t],
    ),
  });

  return (
    <>
      {!showData && (
        <RequiredFiltersAlert i18nKey="common.select_site_year_week_alert" />
      )}
      {showData && (
        <>
          <ReportTable
            data={computedItems as any}
            count={data.count}
            summaryData={data.data.summary}
            isDataLoading={isDataLoading}
            tableUniqueKey={paths.NFI_WEEKLY_INCENTIVE_INDIVIDUAL}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        </>
      )}
    </>
  );
};
