import React from 'react';
import { Sheet } from '@mui/joy';
import { ArrowDownSvg } from 'src/components/svgIcons';
import { PaginationButton } from './PaginationButton';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';

export interface IPaginationButtonsProps {
  count: number;
  rowsPerPage: number;
  currentPageNumber: number;
  isSmallDevice: boolean;
  onPageChange: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    pageNumber: number,
  ) => void;
}

const TOTAL_VISIBLE_PAGES = 6;
const TOTAL_VISIBLE_PAGES_SMALL_DEVICE = 2;
const EDGE_ITEM_INDEX = 2;
const EDGE_ITEM_INDEX_SMALL_DEVICE = 0;

const THREE_DOTS = '...';

export const PaginationButtons = ({
  count,
  rowsPerPage,
  onPageChange,
  currentPageNumber,
  isSmallDevice,
}: IPaginationButtonsProps) => {
  const { t } = useTranslation();

  const totalVisiblePages = isSmallDevice
    ? TOTAL_VISIBLE_PAGES_SMALL_DEVICE
    : TOTAL_VISIBLE_PAGES;
  const edgeItemIndex = isSmallDevice
    ? EDGE_ITEM_INDEX_SMALL_DEVICE
    : EDGE_ITEM_INDEX;

  const totalPages = Math.ceil(count / (rowsPerPage || count));

  const [displayedPagesDetails, setDisplayedPagesDetails] = React.useState<{
    leftSide: Array<number>;
    rightSide: Array<number>;
  }>({
    leftSide: [],
    rightSide: [],
  });

  const handlePreviousClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const prevPage = currentPageNumber - 1;
    if (prevPage >= 0) {
      onPageChange(e, prevPage);

      if (prevPage < displayedPagesDetails.leftSide[0]) {
        setDisplayedPagesDetails((prev) => ({
          ...prev,
          leftSide: prev.leftSide.map((pageNumber) => pageNumber - 1),
        }));

        return;
      }

      if (
        prevPage > displayedPagesDetails.leftSide[edgeItemIndex] &&
        prevPage < displayedPagesDetails.rightSide[0]
      ) {
        setDisplayedPagesDetails((prev) => ({
          ...prev,
          rightSide: prev.rightSide.map((pageNumber) => pageNumber - 1),
        }));
      }
    }
  };

  const handleNextClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const nextPage = currentPageNumber + 1;
    if (nextPage <= totalPages - 1) {
      onPageChange(e, nextPage);

      if (
        nextPage > displayedPagesDetails.leftSide[edgeItemIndex] &&
        nextPage < displayedPagesDetails.rightSide[0]
      ) {
        setDisplayedPagesDetails((prev) => ({
          ...prev,
          leftSide: prev.leftSide.map((pageNumber) => pageNumber + 1),
        }));

        return;
      }

      if (nextPage > displayedPagesDetails.rightSide[edgeItemIndex]) {
        setDisplayedPagesDetails((prev) => ({
          ...prev,
          rightSide: prev.rightSide.map((pageNumber) => pageNumber + 1),
        }));
      }
    }
  };

  React.useEffect(() => {
    // @@TODO: add initialization from snapshot, stored it in query paras, if provided we use it to initialize displayed pages details
    const leftSide = [];
    const rightSide = [];

    const slotsQuantityForLeftSide =
      totalPages <= totalVisiblePages / 2 ? totalPages : totalVisiblePages / 2;
    const slotsQuantityForRightSide =
      totalPages <= totalVisiblePages / 2
        ? 0
        : Math.min(totalPages, totalVisiblePages) - totalVisiblePages / 2;

    let i = 0;
    while (i < slotsQuantityForLeftSide) {
      leftSide.push(i);
      i++;
    }

    let j = slotsQuantityForRightSide;
    while (j > 0) {
      rightSide.push(totalPages - j);
      j--;
    }

    setDisplayedPagesDetails({
      leftSide,
      rightSide,
    });
  }, [totalPages, totalVisiblePages]);

  const lastElementInAGroupIndex = totalVisiblePages / 2 - 1;

  const { leftSide, rightSide } = displayedPagesDetails;

  const should3DotsBeAddedAtTheStart = leftSide[0] > 0;
  const should3DotsBeAddedInTheMiddle =
    rightSide[0] - leftSide[lastElementInAGroupIndex] > 1;
  const should3DotsBeAddedAtTheEnd =
    rightSide[lastElementInAGroupIndex] + 1 < totalPages;

  const displayedPages = [...leftSide, ...rightSide].reduce<
    Array<number | typeof THREE_DOTS>
  >((all, pageNum, i) => {
    if (i === 0 && should3DotsBeAddedAtTheStart) {
      all.push(THREE_DOTS, pageNum);
    } else if (i === totalVisiblePages / 2 && should3DotsBeAddedInTheMiddle) {
      all.push(THREE_DOTS, pageNum);
    } else if (i === totalVisiblePages - 1 && should3DotsBeAddedAtTheEnd) {
      all.push(pageNum, THREE_DOTS);
    } else {
      all.push(pageNum);
    }

    return all;
  }, []);

  return (
    <Sheet sx={{ display: 'flex', alignItems: 'center', bgcolor: 'inherit' }}>
      <PaginationButton
        isSmallDevice={isSmallDevice}
        onClick={handlePreviousClick}
      >
        <ArrowDownSvg style={{ transform: 'rotate(90deg)' }} />
        {!isSmallDevice && (
          <Typography
            level="text_sm"
            fontWeight="semi_bold"
            component="div"
            sx={{ ml: '6px' }}
          >
            {t('common.previous')}
          </Typography>
        )}
      </PaginationButton>
      {displayedPages.map((pageOrThreeDots, i) => {
        return (
          <PaginationButton
            isSmallDevice={isSmallDevice}
            key={pageOrThreeDots + String(i)}
            isThreeDots={pageOrThreeDots === THREE_DOTS}
            isActive={Number(pageOrThreeDots) === Number(currentPageNumber)}
            onClick={
              pageOrThreeDots !== THREE_DOTS
                ? (e) => onPageChange(e, Number(pageOrThreeDots))
                : undefined
            }
          >
            <Typography level="text_sm" fontWeight="medium">
              {pageOrThreeDots !== THREE_DOTS
                ? pageOrThreeDots + 1
                : pageOrThreeDots}
            </Typography>
          </PaginationButton>
        );
      })}
      <PaginationButton isSmallDevice={isSmallDevice} onClick={handleNextClick}>
        {!isSmallDevice && (
          <Typography
            component="div"
            level="text_sm"
            fontWeight="semi_bold"
            sx={{ mr: '6px' }}
          >
            {t('common.next')}
          </Typography>
        )}
        <ArrowDownSvg style={{ transform: 'rotate(-90deg)' }} />
      </PaginationButton>
    </Sheet>
  );
};
