import React from 'react';
import { Box, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  exportBomRequest,
  getBomListRequest,
  getBomCountRequest,
  deleteBomRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import {
  getBomCount,
  getBomsTable,
  getIsBomLoading,
} from 'src/modules/selectors/bom.selector';
import { CreateBom } from './forms/Create';
import { UpdateBoms } from './forms/Update';

export const Boms = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportBomRequest);

  const isDataLoading = useSelector(getIsBomLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.bom.create.id,
  );

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.bom.delete.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.bom.update.id,
  );

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('boms.name'),
      },
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('boms.site'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getBomsTable,
    selectCount: getBomCount,
    getEntitiesRequest: getBomListRequest,
    getEntitiesCountRequest: getBomCountRequest,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  const onDelete = () => {
    clearFilters();

    dispatch(
      deleteBomRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TableContextProvider>
        <TableToolbar>
          <TableTitle>{t('boms.table_name')}</TableTitle>
          <TableActions>
            <Filter onSubmit={onFiltersFormSubmit}>
              <FormFieldContainer>
                <SitesComboBoxFilter
                  label={getLabel({
                    filterName: 'siteId',
                    labelPrefix: t('boms.site'),
                  })}
                  {...getFilterCommonPropsByFilterName('siteId')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'name',
                    labelPrefix: t('boms.name'),
                  })}
                  {...getFilterCommonPropsByFilterName('name')}
                />
              </FormFieldContainer>
            </Filter>

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={filter}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />

            {allowedToCreate && (
              <Button
                sx={{ ml: 1 }}
                onClick={() => setIsCreateFormOpened(true)}
              >
                {t('common.create')}
              </Button>
            )}
          </TableActions>
        </TableToolbar>

        <TableComponent
          tableUniqueKey="setup/production_settings/boms"
          sx={{
            height: isDataLoading || !data.length ? '100%' : 'auto',
            maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
          }}
        >
          {isDataLoading && <MainContentLoader />}

          <TableContent
            data={data}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            isDataLoading={isDataLoading}
            onRowCheckboxClick={(ids) => {
              setSelectedEntitiesIds(ids);
            }}
            selectedRows={selectedEntitiesIds}
            onSort={(orderBy, order) => {
              setOrder(order);
              setOrderBy(orderBy);
            }}
          />

          {Boolean(selectedEntitiesIds.length) && (
            <TableSelectedItemsActions
              selectedItemsQuantity={selectedEntitiesIds.length}
              actionsConfiguration={actionsConfiguration}
              deleteModelName={ModelsToDelete.Bom}
              deleteActionConfiguration={
                allowedToDelete
                  ? {
                      tooltip: t('common.delete'),
                      onClick: onDelete,
                      icon: <Trash04Svg />,
                    }
                  : undefined
              }
            />
          )}

          <TableBottom>
            <Pagination
              currentPageNumber={page}
              count={count}
              rowsPerPage={limit}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={setLimit}
            />
          </TableBottom>
        </TableComponent>
      </TableContextProvider>

      <CreateBom
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateBoms
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        ids={selectedEntitiesIds}
      />
    </Box>
  );
};
