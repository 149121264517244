import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateBom } from '../types';

export const getBomRefreshKey = (state: IStoreState) => state.bom.refreshKey;

export const getBomData = (state: IStoreState) => state.bom;

/**
 * Get server error
 * @param bom - State bom
 */
export const getServerError = ({ bom }: IStoreState) => bom.error;

/**
 * Get bom list
 * @param bom - State bom
 */
export const getBomList = ({ bom }: IStoreState) => bom.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param bom - State bom
 */
export const getBomsTable = createSelector(getBomList, (boms) =>
  map(filter(boms, (s) => !isEmpty(s.site))),
);

/**
 * Get boms by array of ids
 * @param bom - State bom
 */
export const getBomsByIds = createSelector(getBomList, (boms) =>
  memoize((ids: IdsArray) => {
    const initial: IUpdateBom[] = [];
    return reduce(
      boms,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const bomData = omit(cur, ['site']);
          acc.push(bomData);
        }
        return acc;
      },
      initial,
    );
  }),
);

/**
 * Get bom count
 * @param bom - State bom
 */
export const getBomCount = ({ bom }: IStoreState) => bom.count;

/**
 * Get combobox list
 */
export const getBomsComboboxList = ({ bom }: IStoreState) =>
  map(bom.comboboxList, (bomItem) => ({
    id: bomItem.id,
    name: bomItem.name,
    siteId: bomItem.siteId,
  }));

export const getIsBomLoading = createSelector(
  getBomData,
  (data) =>
    data.isBomDataLoading ||
    data.isBomDataCountLoading ||
    data.isBomDataManagingLoading,
);
