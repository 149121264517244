import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClientKey } from 'src/modules/constants';
import {
  ReportPageContext,
  ReportPageContextProvider,
} from './ReportPageContext';
import { PageContentChildContainer } from '../PageContent';
import { TableContextProvider } from '../Table/TableContext';
import { RequiredFiltersAlert } from '../RequiredFiltersAlert';
export * from './Report';

interface IReportContainerProps {
  client?: ClientKey;
  hideNoData?: boolean;
  fullHeight?: boolean;
  disableHorizontalPadding?: boolean;
}

const ReportContainer: React.FC<IReportContainerProps> = ({
  children,
  hideNoData,
  fullHeight = true,
  disableHorizontalPadding = false,
}) => {
  // const classes = useReportPageStyles();
  const { t } = useTranslation();

  const {
    activeReportId,
    setActiveReportId,
    activeReportTypeId,
    reportsListByReportType,
  } = React.useContext(ReportPageContext);

  const shouldSelectReportAndReportTypeComponentBeRendered =
    (!activeReportId || !activeReportTypeId) && !hideNoData;

  // For Gaps Report charts available by click on table row
  // const isGapsReport = activeReportTypeId === 'gaps';
  // let chartButtonTooltip = '';
  // if (isGapsReport) {
  //   chartButtonTooltip = t('reports.chart_by_click');
  // } else if (!shouldSwitchToChartBtnBeActive) {
  //   chartButtonTooltip = t('reports.no_chart');
  // }

  React.useEffect(() => {
    if (!activeReportTypeId) {
      return;
    }

    const isSelectedReportExistsForSelectedReportType = reportsListByReportType[
      activeReportTypeId
    ].some((item) => item.reportId === activeReportId);

    if (!isSelectedReportExistsForSelectedReportType) {
      setActiveReportId(null);
    }
  }, [
    activeReportId,
    activeReportTypeId,
    reportsListByReportType,
    setActiveReportId,
  ]);

  let sx: any = {
    display: 'flex',
    flexDirection: 'column',
  };
  if (disableHorizontalPadding) {
    sx = {
      ...sx,
      px: 0,
    };
  }

  return (
    <PageContentChildContainer fullHeight={fullHeight} sx={sx}>
      {children}

      {shouldSelectReportAndReportTypeComponentBeRendered && (
        <RequiredFiltersAlert
          i18nKey={t('reports.select_report_type_and_report')}
        />
      )}
    </PageContentChildContainer>
  );
};

export const ReportPage: React.FC<IReportContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <ReportPageContextProvider>
      <TableContextProvider>
        <ReportContainer {...rest}>{children}</ReportContainer>
      </TableContextProvider>
    </ReportPageContextProvider>
  );
};
