import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { ICountResponse, IBomModel, IBomState } from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IBomState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  siteId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  isBomDataLoading: false,
  isBomDataCountLoading: false,
  isBomDataManagingLoading: false,
};

/**
 * Flush actions
 */
const flushBomState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createBomRequest = (state: IBomState) => ({
  ...state,
  error: false,
  isBomDataManagingLoading: true,
});

const createBomSuccess = (state: IBomState, payload: IBomModel) => ({
  ...state,
  ...payload,
  error: false,
  isBomDataManagingLoading: false,
});

const createBomFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  isBomDataManagingLoading: false,
});

/**
 * Get list actions
 */
const getBomListRequest = (state: IBomState) => ({
  ...state,
  error: false,
  isBomDataLoading: true,
});

const getBomListSuccess = (state: IBomState, payload: IBomModel[]) => ({
  ...state,
  list: payload,
  error: false,
  isBomDataLoading: false,
});

const getBomListFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  isBomDataLoading: false,
});

/**
 * Get count actions
 */
const getBomCountRequest = (state: IBomState) => ({
  ...state,
  error: false,
  isBomDataCountLoading: true,
});

const getBomCountSuccess = (state: IBomState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  isBomDataCountLoading: false,
});

const getBomCountFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  isBomDataCountLoading: false,
});

/**
 * Deletion actions
 */
const deleteBomRequest = (state: IBomState) => ({
  ...state,
  error: false,
  isBomDataManagingLoading: true,
});

const deleteBomSuccess = (state: IBomState) => ({
  ...state,
  isBomDataManagingLoading: false,
});

const deleteBomFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  isBomDataManagingLoading: false,
});

/**
 * Updating actions
 */
const updateBomsRequest = (state: IBomState) => ({
  ...state,
  error: false,
  isBomDataManagingLoading: true,
});

const updateBomsSuccess = (state: IBomState) => ({
  ...state,
  isBomDataManagingLoading: false,
});

const updateBomsFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  isBomDataManagingLoading: false,
});

/**
 * Get combobox list actions
 */
const getBomComboboxListRequest = (state: IBomState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getBomComboboxListSuccess = (
  state: IBomState,
  payload: Partial<IBomModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getBomComboboxListFailed = (state: IBomState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IBomState>();

const exportBomRequest = exportRequest;
const exportBomSuccess = exportSuccess;
const exportBomFailed = exportFailed;

/**
 * Bom reducer
 */
export const bom = createReducer<IBomState>({}, defaultState)
  // flush actions
  .on(actions.flushBomState, flushBomState)
  // creation actions
  .on(actions.createBomRequest, createBomRequest)
  .on(actions.createBomSuccess, createBomSuccess)
  .on(actions.createBomFailed, createBomFailed)
  // get list actions
  .on(actions.getBomListRequest, getBomListRequest)
  .on(actions.getBomListSuccess, getBomListSuccess)
  .on(actions.getBomListFailed, getBomListFailed)
  // get count actions
  .on(actions.getBomCountRequest, getBomCountRequest)
  .on(actions.getBomCountSuccess, getBomCountSuccess)
  .on(actions.getBomCountFailed, getBomCountFailed)
  // deletion actions
  .on(actions.deleteBomRequest, deleteBomRequest)
  .on(actions.deleteBomSuccess, deleteBomSuccess)
  .on(actions.deleteBomFailed, deleteBomFailed)
  // updating actions
  .on(actions.updateBomsRequest, updateBomsRequest)
  .on(actions.updateBomsSuccess, updateBomsSuccess)
  .on(actions.updateBomsFailed, updateBomsFailed)
  // get combobox list actions
  .on(actions.getBomComboboxListRequest, getBomComboboxListRequest)
  .on(actions.getBomComboboxListSuccess, getBomComboboxListSuccess)
  .on(actions.getBomComboboxListFailed, getBomComboboxListFailed)
  // export actions
  .on(actions.exportBomRequest, exportBomRequest)
  .on(actions.exportBomSuccess, exportBomSuccess)
  .on(actions.exportBomFailed, exportBomFailed);

export default bom;
