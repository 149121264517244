import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICommonFilterProps } from 'src/components/Table/components/TableActions/components/Filter/types';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { useFetchNotArchivedDepartmentsCombobox } from 'src/modules/utils';
import { getDepartmentOptionsByGloballySitesWithPropAsId } from 'src/modules/selectors/department';

type IDepartmentsComboBoxFilterProps = Omit<
  IComboboxProps,
  'value' | 'options' | 'name'
> &
  ICommonFilterProps & {
    propertyAsId?: 'id' | 'name';
  };

export const DepartmentsComboBoxFilter: React.FC<IDepartmentsComboBoxFilterProps> = ({
  value,
  name,
  propertyAsId = 'id',
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchDepartmentsCombobox = useFetchNotArchivedDepartmentsCombobox();

  const options = useSelector(getDepartmentOptionsByGloballySitesWithPropAsId)(
    propertyAsId,
  );

  useEffect(() => {
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueId = getFilterByName(name) ?? value;

  const parsedValueId = propertyAsId === 'id' ? Number(valueId) : valueId;

  const comboBoxValue =
    options.find((option) => option.id === parsedValueId) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={options}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
