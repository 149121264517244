import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { ProductionSettings } from './panels/Productions';
import { Boms } from './panels/Boms';

export const ProductionSettingsPage = () => {
  const { t } = useTranslation();

  const hasUserAccessSettingsTab = useHasUserAccessToViewTab(
    tabsPaths.PRODUCTION_SETTINGS_TAB,
  );
  const hasUserAccessBomTab = useHasUserAccessToViewTab(
    tabsPaths.PRODUCTION_SETTINGS_BOMS_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('production_settings.settings'),
          hasUserAccess: hasUserAccessSettingsTab,
          component: <ProductionSettings />,
        },
        {
          title: t('production_settings.bom'),
          hasUserAccess: hasUserAccessBomTab,
          component: <Boms />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [hasUserAccessSettingsTab, hasUserAccessBomTab, t],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
