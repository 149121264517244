import React from 'react';
import { isObject, reduce } from 'lodash';
import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { ITableBodyItem } from 'src/components/Table';
import {
  useTableData,
  DEFAULT_PAGE,
  DEFAULT_LIMIT,
  useTableCommonHandlers,
} from 'src/modules/utils/hooks/table';
import { AnyObject } from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ClientKey } from 'src/modules/constants';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { useExceptionsFromProductionHeadCellsData } from 'src/modules/utils/hooks/exceptionsFromProduction.hooks';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface IExceptionsFromProductionReportProps {
  siteId?: number;
  client: ClientKey;
  headCellsToOmit: string[];
}

export const ExceptionsFromProductionReport: React.FC<IExceptionsFromProductionReportProps> = ({
  siteId,
  client,
  headCellsToOmit,
}) => {
  const reportUrl = React.useMemo(
    () => `/${client}/exceptions/production/from-production`,
    [client],
  );

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'id',
  };

  const where = React.useMemo(
    () => ({
      siteId: { inq: [siteId] },
    }),
    [siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<AnyObject>(
    reportUrl,
    {
      data: [],
      count: 0,
    },
  );

  const isSiteSelected = React.useMemo(() => !!siteId, [siteId]);

  const {
    headCells,
    headCellsOrderDetails,
  } = useExceptionsFromProductionHeadCellsData({
    list: (data.data as unknown) as Record<string, string | number>[],
    headCellsToOmit,
  });

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    ...(isSiteSelected ? { fetchData } : {}),
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  const list = React.useMemo(
    () =>
      reduce(
        data.data,
        (datalist, item) => {
          datalist.push(
            reduce(
              item,
              (obj, value, key) => {
                (obj as any)[key] = isObject(value) ? (
                  <JsonView
                    data={value}
                    shouldExpandNode={() => false}
                    style={defaultStyles}
                  />
                ) : (
                  value || ''
                );
                return obj;
              },
              {} as ITableBodyItem,
            ),
          );
          return datalist;
        },
        [] as ITableBodyItem[],
      ),
    [data.data],
  );

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && (
        <ReportTable
          data={list as any}
          count={data.count as number}
          isDataLoading={isDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells as any}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
