import React from 'react';
import { ExceptionsFromProductionReport } from 'src/components/Reports/ExceptionsFromProductionReport';
import { clients } from '../../modules/constants';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

const headCellsToOmit = ['id', 'siteId', 'shiftId', 'date', 'endDate'];

interface IPerryEllisExceptionFromProductionProps {
  siteId?: number;
}

export const PerryEllisExceptionFromProduction = ({
  siteId,
}: IPerryEllisExceptionFromProductionProps) => {
  const isSiteSelected = Boolean(siteId);

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && (
        <ExceptionsFromProductionReport
          siteId={siteId}
          client={clients.perryEllis}
          headCellsToOmit={headCellsToOmit}
        />
      )}
    </>
  );
};
