import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import {
  DataGridNgroupContainer,
  DataGridNgroupTable,
} from 'src/components/DataGridNgroup';
import { Api, useIncentivePricePermissions } from 'src/modules/utils';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import {
  IPricingFilterPanelFilters,
  PricingFilterPanel,
} from '../../PricingFilterPanel';
import { IncentivePriceSettingsOptions } from 'src/modules/types';
import { useIncentivePricingSettingsForm } from 'src/modules/utils/hooks/incentive-pricing-settings';
import { PageContentChildContainer } from 'src/components/PageContent';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { Button } from '@mui/joy';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IInclusionObject, InclusionPath } from 'src/modules/types/table';
import {
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import {
  GridColWithOrderConfig,
  IGridValidationError,
} from 'src/modules/types/data-grid';
import { useGenerateGridColumnsData } from 'src/modules/utils/hooks/data-grid.hooks';
import { useSelector } from 'react-redux';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import * as Yup from 'yup';
import {
  NEW_GRID_ROW_ID_PREFIX,
  validateGridData,
} from 'src/components/DataGridNgroup/dataGridHelpers';
import * as uuid from 'uuid';
import { IncentivePriceSettingsCreate } from './components/IncentivePriceSettingsCreate';
import { DataGridExportData } from 'src/components/DataGridNgroup';

const inclusionObj: IInclusionObject = {
  site: {
    relationType: 'left',
  },
};

const defaultSettings: Array<IncentivePriceSettingsOptions> = [];

const reportUrl = '/incentive-prices';

export const IncentivePriceList = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl, true);

  const [siteId, setSiteId] = React.useState<number | undefined>();

  const { settings = defaultSettings } = useIncentivePricingSettingsForm(
    siteId,
  );

  const sites = useSelector(getSitesComboboxList);

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
    allowedToCreateSettings,
  } = useIncentivePricePermissions();

  const siteFilterConfig: Array<{
    id: keyof IPricingFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('incentivePrice.site'),
        isPreselected: true,
        isPermanent: true,
      },
    ],
    [t],
  );

  const [
    isIncentivePriceSettingsCreateOpen,
    setIncentivePriceSettingsCreateOpen,
  ] = React.useState(false);

  const where = React.useMemo(
    () => ({
      siteId: siteId ? siteId : undefined,
    }),
    [siteId],
  );

  const headCellsConfig: Array<GridColWithOrderConfig> = React.useMemo(
    () => [
      {
        field: 'siteName',
        editable: false,
        flex: 1,
        headerName: t('incentivePrice.site'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'] as InclusionPath,
        },
      },
      {
        field: 'price',
        editable: true,
        flex: 1,
        headerName: t('incentivePrice.price'),
        orderConfig: { orderBy: 'price' },
      },
      ...(settings ?? []).map(({ name, title }) => ({
        field: name,
        editable: true,
        flex: 1,
        headerName: title,
        orderConfig: { orderBy: name },
      })),
    ],
    [settings, t],
  );

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, []);

  const selectSiteFullData = React.useMemo(
    () => sites.find((item) => item.id === siteId),
    [siteId, sites],
  );

  const creteNewGridRow = () => {
    return {
      siteId,
      isNew: true,
      id: `${NEW_GRID_ROW_ID_PREFIX}${uuid.v4()}`,
      siteName: selectSiteFullData?.name,
      ...settings.map(({ name }) => ({
        [name]: '',
      })),
    };
  };

  const existedRows = React.useMemo(() => {
    return data.map((item: any) => ({
      ...item,
      isNew: false,
      siteName: item.site.name,
    }));
  }, [data]) as any;

  const [newRows, setNewRows] = React.useState<
    Array<ReturnType<typeof creteNewGridRow>>
  >([]);

  const rows = React.useMemo(() => [...newRows, ...existedRows], [
    existedRows,
    newRows,
  ]);

  const [
    gridValidationError,
    setGridValidationError,
  ] = React.useState<IGridValidationError | null>(null);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        price: Yup.string().required(t('common.required')),
        ...(settings ?? []).reduce((all, item) => {
          all[item.name] = Yup.string().required(t('common.required'));
          return all;
        }, {}),
      }),
    [settings, t],
  );

  const { columns, gridColumnsOrderDetails } = useGenerateGridColumnsData(
    headCellsConfig,
    gridValidationError,
  );

  const { order, orderBy, setOrder, setOrderBy } = useTableData({
    headCellsOrderDetails: gridColumnsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'price',
  });

  const listFilter = useGenerateRequestFilter({
    order,
    orderBy,
    inclusionObj,
    defaultWhere: where,
    headCellsOrderDetails: gridColumnsOrderDetails,
  });

  const handleDelete = async (id: GridRowId) => {
    // Case when user deletes new row
    if (String(id).startsWith(NEW_GRID_ROW_ID_PREFIX)) {
      setNewRows((prev) => prev.filter((item) => item.id !== id));
    } else {
      // Case when user deletes existed row
      await removeDataByIds([id]);

      await fetchData({
        filter: listFilter,
      });
    }
  };

  const handleCreate = async (row: GridValidRowModel) => {
    const requestData = {
      price: Number(row.price),
      siteId: row.siteId,
      metadata: settings.map((item) => ({
        key: item.name,
        value: (row[item.name] ?? '').trim(),
      })),
    };

    await Api.IncentivePrice.create(requestData);

    setNewRows((prev) => prev.filter((item) => item.id !== row.id));

    await fetchData({
      filter: listFilter,
    });
  };

  const handleUpdate = async (row: GridValidRowModel) => {
    const requestData = {
      id: row.id,
      price: Number(row.price),
      siteId: row.siteId,
      metadata: settings.map((item) => ({
        key: item.name,
        value: (row[item.name] ?? '').trim(),
      })),
    };

    await Api.IncentivePrice.bulkUpdate([requestData]);

    await fetchData({
      filter: listFilter,
    });
  };

  const handleAddRow = () => {
    setNewRows((prev) => [...prev, creteNewGridRow()]);
  };

  React.useEffect(() => {
    if (gridValidationError) {
      return;
    }
    fetchData({ filter: listFilter });
  }, [fetchData, gridValidationError, listFilter]);

  return (
    <>
      <DataGridNgroupContainer>
        <TableToolbar>
          <TableTitle>{t('pricing.incentive')}</TableTitle>

          <TableActions>
            <DataGridExportData
              requestFilters={listFilter}
              exportBtnContainerProps={{ ml: 3 }}
              headCells={columns.map((column) => ({
                isVisible: true,
                label: column.headerName as string,
                id: column.field === 'siteName' ? 'site.name' : column.field,
              }))}
              {...exportProps}
            />

            {allowedToCreateSettings && (
              <Button
                sx={{ ml: 1 }}
                onClick={() => setIncentivePriceSettingsCreateOpen(true)}
              >
                {t('pricing.create_settings_btn')}
              </Button>
            )}

            {allowedToCreate && (
              <ReportPageActionsGroupItem>
                <Button disabled={!siteId} onClick={handleAddRow}>
                  {t('common.add_row')}
                </Button>
              </ReportPageActionsGroupItem>
            )}
          </TableActions>
        </TableToolbar>

        <PageContentChildContainer fullHeight={false} sx={{ px: 0, pb: 1 }}>
          <PricingFilterPanel
            onApplyFilters={({ siteId }) => setSiteId(siteId)}
            onRemoveAllFilters={() => setSiteId(undefined)}
            filtersOptionsConfig={siteFilterConfig}
            defaultFilters={{ siteId }}
          />
        </PageContentChildContainer>

        {!siteId && (
          <PageContentChildContainer sx={{ px: 0 }}>
            <RequiredFiltersAlert
              i18nKey="filters.site_alert"
              values={{ site: 'Site' }}
            />
          </PageContentChildContainer>
        )}

        {siteId && (
          <DataGridNgroupTable
            rows={rows}
            columns={columns}
            isDataLoading={isDataLoading}
            disablePagination={true}
            onProcessRowUpdate={
              allowedToCreate || allowedToUpdate
                ? async (row) => {
                    const errors = await validateGridData(
                      validationSchema,
                      row,
                    );

                    if (errors) {
                      setGridValidationError((prev) => ({
                        ...prev,
                        ...errors,
                      }));
                    } else {
                      setGridValidationError(null);

                      if (row.isNew) {
                        await handleCreate(row);
                      } else {
                        await handleUpdate(row);
                      }
                    }

                    return row;
                  }
                : undefined
            }
            onRowDelete={allowedToDelete ? handleDelete : undefined}
            containerSx={{ maxHeight: 'calc(100% - 124px)' }}
            sortProps={{
              order,
              orderBy,
              onSort: (orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              },
            }}
          />
        )}
      </DataGridNgroupContainer>

      <IncentivePriceSettingsCreate
        isOpen={isIncentivePriceSettingsCreateOpen}
        onClose={() => setIncentivePriceSettingsCreateOpen(false)}
      />
    </>
  );
};
