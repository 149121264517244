import React from 'react';
import { SelectProps } from '@mui/joy';
import { Select } from 'src/components/_ui-kit/Select';
import { Pencil02Svg } from 'src/components/svgIcons';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';

type IFilterPanelSelectProps = Omit<
  SelectProps<any, any>,
  'listboxOpen' | 'onListboxOpenChange' | 'variant'
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
};

export const FilterPanelSelect = ({
  filterId,
  onRemoveFilter,
  ...props
}: IFilterPanelSelectProps) => {
  const {
    setEditingFiltersIds,
    elementsHeight,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
  } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  const [listboxOpen, onListboxOpenChange] = React.useState(false);

  const onListboxOpenChangeHandler = () => {
    if (!listboxOpen && !isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
    // we need it because when user clicks Add filter button
    // or edit any filter UI of all filters are changed
    // and dropdowns shifted too much from their required positions
    if (filterPanelMode === FilterPanelModes.ready) {
      setTimeout(() => onListboxOpenChange((prev) => !prev), 300);
    } else {
      onListboxOpenChange((prev) => !prev);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <Select
        {...props}
        listboxOpen={listboxOpen}
        onListboxOpenChange={onListboxOpenChangeHandler}
        indicator={isFilterInEditingMode ? undefined : <Pencil02Svg />}
        variant="plain"
        slotProps={{
          root: {
            sx: {
              fontSize: '0.75rem',
              '& li.MuiOption-root': {
                fontSize: '0.875rem',
              },
              borderRadius: 0,
              margin: 0,
              height: elementsHeight,
            },
          },
        }}
      />
    </FilterPanelFilterContainer>
  );
};
