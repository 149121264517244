import React from 'react';
import { Sheet, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export interface ICurrentViewDetailsProps {
  count: number;
  rowsPerPage: number;
  currentPageNumber: number;
  isSmallDevice: boolean;
}

export const CurrentViewDetails = ({
  count,
  rowsPerPage,
  currentPageNumber,
  isSmallDevice,
}: ICurrentViewDetailsProps) => {
  const { t } = useTranslation();

  const firstCurrentRowNumber = currentPageNumber * rowsPerPage + 1;
  const lastCurrentRowNumber = rowsPerPage + currentPageNumber * rowsPerPage;

  const currentPageDetails = `${firstCurrentRowNumber}-${
    lastCurrentRowNumber < count ? lastCurrentRowNumber || count : count
  }`;

  return (
    <Sheet
      sx={{ bgcolor: 'inherit', pl: isSmallDevice ? 0 : '40px', pr: '4px' }}
    >
      <Typography
        level="text_xs"
        fontWeight="regular"
        textTransform="lowercase"
        textColor="gray.600"
      >{`${currentPageDetails} ${t('common.of')} ${
        Number(count) ? count : Number(count) + 1
      }`}</Typography>
    </Sheet>
  );
};
