import React from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from '../../config';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { useProductionMetatagColumns } from 'src/modules/utils';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

interface WeeklyCpuProps {
  siteId?: number;
  year?: number;
  week?: number;
}

const reportDataUrl = '/perry-ellis/weekly-cpu';

export const WeeklyCpu = ({ siteId, year, week }: WeeklyCpuProps) => {
  const { t } = useTranslation();

  const isSiteSelected = Boolean(siteId);

  const { dynamicColumns } = useProductionMetatagColumns(siteId ?? -1);

  const filteredDynamicColumns = React.useMemo(() => {
    return dynamicColumns.filter((column) =>
      ['department'].includes((column.label as string).toLowerCase()),
    );
  }, [dynamicColumns]);

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId: siteId ?? undefined,
      year: year ?? undefined,
      week: week ?? undefined,
    }),
    [siteId, year, week],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportDataUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportDataUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('productions_uph_reports.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('productions_uph_reports.week'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      ...filteredDynamicColumns,
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'price',
        orderConfig: { orderBy: 'price' },
        label: t('productions_uph_reports.price'),
      },
      {
        id: 'cpu',
        orderConfig: { orderBy: 'cpu' },
        label: t('productions_uph_reports.cpu'),
      },
    ],
    [filteredDynamicColumns, t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <>
      {!isSiteSelected && (
        <RequiredFiltersAlert
          i18nKey="filters.site_alert"
          values={{ site: 'Site' }}
        />
      )}
      {isSiteSelected && (
        <ReportTable
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={paths.WILLIAMS_SONOMA_WEEKLY_CPU}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
