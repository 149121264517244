import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPage } from 'src/components/ReportPage';
import { WeeklyHoursDashboardTable } from 'src/pages/TimeKeeping/WeeklyHoursDashboard/panels/WeeklyHoursDashboardTable';
import { WeeklyHoursDashboardChart } from 'src/pages/TimeKeeping/WeeklyHoursDashboard/panels/WeeklyHoursDashboardChart';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import {
  IWeeklyHoursDashboardFilterPanelFilters,
  WeeklyHoursDashboardFilterPanel,
} from 'src/pages/TimeKeeping/WeeklyHoursDashboard/panels/components/WeeklyHoursDashboardFilter';
import { useCreateReportFiltersConfigWithDefaultOptions } from 'src/modules/utils/hooks/reportPage.hooks';
import { isEmpty } from 'lodash';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';
import { Box } from '@mui/joy';

export const WeeklyHoursDashboard: React.FC = () => {
  const { t } = useTranslation();

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IWeeklyHoursDashboardFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('payboard.site') },
      { id: 'yearWeek', label: t('payboard.year_week') },
      { id: 'department', label: t('payboard.department') },
    ],
    [t],
  );

  const defaultFilters: IWeeklyHoursDashboardFilterPanelFilters = {
    siteId: undefined,
    department: undefined,
    yearWeek: undefined,
  };

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IWeeklyHoursDashboardFilterPanelFilters
  >(defaultFilters);

  const showData =
    !!appliedFilters?.siteId && !isEmpty(appliedFilters?.yearWeek);

  return (
    <>
      <PageContentWithTopToolbar>
        <ReportPage hideNoData>
          <ReportTableToolbar
            reportName={t('payboard.weekly_hours_summary_table_name')}
          >
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
          <ReportPageItemContainer>
            <WeeklyHoursDashboardFilterPanel
              hideSyncBtn
              filtersOptionsConfig={filtersOptionsConfig}
              onApplyFilters={(filtersToApply) => {
                setAppliedFilters(filtersToApply);
              }}
              onRemoveAllFilters={() => setAppliedFilters(defaultFilters)}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            {!showData && (
              <RequiredFiltersAlert
                i18nKey="payboard.site_filter_alert"
                values={{ site: 'Site' }}
              />
            )}
            {showData && (
              <>
                <Box>
                  <WeeklyHoursDashboardChart
                    siteId={appliedFilters.siteId}
                    yearWeek={appliedFilters?.yearWeek}
                  />
                </Box>

                <Box sx={{ pt: 2 }}>
                  <WeeklyHoursDashboardTable
                    siteId={appliedFilters.siteId}
                    yearWeek={appliedFilters?.yearWeek}
                    department={appliedFilters?.department}
                  />
                </Box>
              </>
            )}
          </ReportPageItemContainer>
        </ReportPage>
      </PageContentWithTopToolbar>
    </>
  );
};
