import React from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { FormLabelProps } from '@mui/joy/FormLabel';
import FormHelperText, { FormHelperTextProps } from '@mui/joy/FormHelperText';
import {
  TextareaProps,
  Typography,
  useTheme,
  Textarea as JoyTextarea,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/joy/styles/types';

export interface ITextareaProps extends TextareaProps {
  label?: string;
  helperText?: string;
  helperTextProps?: Omit<FormHelperTextProps, 'children'>;
  labelProps?: Omit<FormLabelProps, 'label'>;
  formControlSx?: SxProps;
}

export const Textarea = ({
  label,
  error,
  labelProps,
  helperText,
  helperTextProps,
  placeholder,
  formControlSx,
  ...textareaProps
}: ITextareaProps) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <FormControl error={error} sx={formControlSx}>
      {label && (
        <FormLabel {...labelProps}>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary.main"
          >
            {label}
          </Typography>
        </FormLabel>
      )}
      <JoyTextarea
        sx={{
          bgcolor: 'inherit',
          '&::placeholder': {
            fontWeight: theme.fontWeight.regular,
            color: theme.palette.colors.text.text_placeholder,
            ...theme.typography.text_md,
          },
        }}
        {...textareaProps}
        placeholder={placeholder ?? t('common.add_your_text_here')}
      />
      {helperText && (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
