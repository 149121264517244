import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/joy';
import { chain, cloneDeep, get, set } from 'lodash';
import { productionBaseFields, Api } from '../../../../modules/utils';
import ComboBox, { ComboBoxOption } from '../../../../components/ComboBox';
import {
  IManualProductionSettings,
  ProductionSettingConfig,
} from '../../../../modules/types';
import { FormFieldContainer } from '../../../../components/Form/FormFieldContainer';
import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';
import { TextField } from 'src/components/_ui-kit/TextField';
import { Textarea } from 'src/components/_ui-kit/Textarea';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { addProcessStatus } from 'src/modules/actions';
import { useDispatch } from 'react-redux';

const optionsSeparator = '\n';

const ProductionSettingsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [siteId, setSiteId] = React.useState<number | null>(null);

  const [isDataProcessing, setIsDataProcessing] = React.useState(false);

  const {
    data: config,
    fetchData,
    isDataLoading,
  } = useDataFetcherWithData<IManualProductionSettings | null>(
    '/production-settings',
    null,
  );

  const metatags = React.useMemo(
    () =>
      chain(config?.metatagColumns)
        .reduce((acc, cur) => {
          const mt = get(config, ['settings', 'metatags', cur.name]);

          if (mt) {
            (acc as any)[cur.name] = { ...mt };
          } else {
            (acc as any)[cur.name] = {
              type: 'text',
              name: cur.name,
              title: cur.name,
              options: [],
            };
          }

          return acc;
        }, {})
        .value(),
    [config],
  );

  const fields = React.useMemo(
    () =>
      chain(Object.keys(productionBaseFields))
        .reduce((acc, cur) => {
          (acc as any)[cur] = get(
            config?.settings?.fields && (config as any)?.settings?.fields[cur]
              ? config.settings?.fields
              : productionBaseFields,
            [cur],
          );
          return acc;
        }, {} as ProductionSettingConfig['fields'])
        .value(),
    [config],
  );

  const [productionSettings, setProductionSettings] = React.useState<
    ProductionSettingConfig
  >({
    fields,
    metatags,
  });

  const handleTitleChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value);
      return value;
    });
  };

  const handleMetatagOptionsChange = (id: string) => (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value.split(optionsSeparator));
      return value;
    });
  };

  const handleSwitchChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.checked);
      return value;
    });
  };

  const handleMetatagTypeChange = (id: string) => (
    e: React.SyntheticEvent<Element, Event>,
    v: ComboBoxOption | null,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, v?.id);
      return value;
    });
  };

  const handleSave = async () => {
    try {
      setIsDataProcessing(true);

      if (config?.id) {
        await Api.Production.updateSettings({
          id: config.id,
          settings: productionSettings,
        });
      } else {
        await Api.Production.createSettings({
          siteId,
          settings: productionSettings,
        });
      }

      setIsDataProcessing(false);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
    } catch {
      setIsDataProcessing(false);
    }
  };

  const metatagTypesOptions = React.useMemo(
    () =>
      ([
        {
          id: 'text',
          name: 'text',
        },
        {
          id: 'combobox',
          name: 'combobox',
        },
        {
          id: 'checkbox',
          name: 'checkbox',
        },
      ] as unknown) as ComboBoxOption[],
    [],
  );

  const getOption = React.useCallback(
    (type: string) => metatagTypesOptions.find((i) => `${i.id}` === type),
    [metatagTypesOptions],
  );

  const isLoading = isDataLoading || isDataProcessing;

  React.useEffect(() => setProductionSettings({ fields, metatags }), [
    metatags,
    fields,
  ]);

  React.useEffect(() => {
    if (siteId) {
      fetchData({ filter: { where: { siteId: { eq: siteId } } } });
    }
  }, [fetchData, siteId]);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <TableToolbar>
        <TableTitle>{t('production_settings.main')}</TableTitle>

        <TableActions>
          <Filter onSubmit={({ siteId }) => setSiteId(siteId)}>
            <FormFieldContainer>
              <SitesComboBoxFilter
                value={siteId}
                label={t('common.site')}
                name="siteId"
              />
            </FormFieldContainer>
          </Filter>
        </TableActions>
      </TableToolbar>

      {!siteId && !isLoading && (
        <RequiredFiltersAlert
          i18nKey="production_settings.select_site_alert"
          values={{ site: 'Site' }}
        />
      )}

      {siteId && (
        <Box
          sx={{
            position: 'relative',
            borderRadius: 'md',
            border: '1px solid',
            borderColor: 'colors.border.border_primary',
            px: 1,
            display: 'grid',
            gridTemplateColumns: { md: '1fr', lg: '1fr 2fr' },
            gap: 1,
          }}
        >
          {isLoading && <MainContentLoader />}

          {/* Left Container */}
          <Box
            sx={{
              py: 1,
              borderRight: {
                md: 'none',
                lg: '1px solid #D0D5DD',
              },
              borderBottom: {
                md: '1px solid #D0D5DD',
                lg: 'none',
              },
            }}
          >
            <Box sx={{ pb: 1 }}>
              <Typography
                level="text_xl"
                textColor="colors.text.text_tertiary.main"
              >
                {t('production_settings.form_fields_title')}
              </Typography>
            </Box>

            <Box
              sx={{
                p: 1,
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                gap: 2,
              }}
            >
              {/* Columns headers */}
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('common.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('common.active')}
                </Typography>
              </Box>

              {/* Columns values */}
              {chain(productionSettings.fields)
                .map(({ title, enabled }, fieldName) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        height: 40,
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        formControlSx={{ width: '100%' }}
                        size="md"
                        variant="soft"
                        value={title}
                        disabled={!enabled}
                        onChange={handleTitleChange(
                          `fields.${fieldName}.title`,
                        )}
                      />
                    </Box>

                    <Box
                      sx={{ display: 'flex', height: 40, alignItems: 'center' }}
                    >
                      <Checkbox
                        size="lg"
                        checked={enabled}
                        onChange={handleSwitchChange(
                          `fields.${fieldName}.enabled`,
                        )}
                      />
                    </Box>
                  </>
                ))
                .value()}
            </Box>
          </Box>

          {/* Right Container */}
          <Box sx={{ py: 1 }}>
            <Box sx={{ pb: 1 }}>
              <Typography
                level="text_xl"
                textColor="colors.text.text_tertiary.main"
              >
                {t('production_settings.metatags_fields_title')}
              </Typography>
            </Box>

            <Box
              sx={{
                p: 1,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 3fr',
                gap: 2,
              }}
            >
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('common.name')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('common.title')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('common.type')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  level="text_xs"
                  fontWeight="semi_bold"
                  textColor="colors.text.text_tertiary.main"
                >
                  {t('production_settings.metatag.options')}
                </Typography>
              </Box>

              {chain(productionSettings.metatags)
                .map(({ title, name, type, options }) => (
                  <>
                    <Box
                      sx={{ display: 'flex', height: 40, alignItems: 'center' }}
                    >
                      <Typography level="text_sm">{name}</Typography>
                    </Box>

                    <Box
                      sx={{ display: 'flex', height: 40, alignItems: 'center' }}
                    >
                      <TextField
                        formControlSx={{ width: '100%' }}
                        size="md"
                        variant="soft"
                        fullWidth
                        value={title}
                        onChange={handleTitleChange(`metatags.${name}.title`)}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        height: 40,
                        alignItems: 'center',
                        position: 'relative',
                        top: '-3px',
                      }}
                    >
                      <ComboBox
                        size="md"
                        disableClearable={true as any}
                        variant="soft"
                        placeholder={t('production_settings.metatag.type')}
                        options={metatagTypesOptions}
                        value={getOption(type)}
                        onChange={handleMetatagTypeChange(
                          `metatags.${name}.type`,
                        )}
                      />
                    </Box>

                    <Box
                      sx={{ display: 'flex', height: 40, alignItems: 'center' }}
                    >
                      {type === 'combobox' && (
                        <Textarea
                          formControlSx={{ width: '100%' }}
                          size="md"
                          variant="soft"
                          value={options?.join(optionsSeparator)}
                          maxRows={2}
                          onChange={handleMetatagOptionsChange(
                            `metatags.${name}.options`,
                          )}
                        />
                      )}
                    </Box>
                  </>
                ))
                .value()}
            </Box>
          </Box>
        </Box>
      )}

      {siteId && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
          <Button onClick={handleSave} sx={{ width: 200 }}>
            {t('common.save')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const ProductionSettings = () => {
  return (
    <TableContextProvider>
      <ProductionSettingsComponent />
    </TableContextProvider>
  );
};
