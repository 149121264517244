import { Box, Button, Sheet, Typography, useTheme } from '@mui/joy';
import { useFormik } from 'formik';
import { omit, set } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ComboBoxOption } from 'src/components/ComboBox';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { addProcessStatus, setIsDataRequested } from 'src/modules/actions';
import { createSiteBillingDetailsScheme } from 'src/modules/schemes/site-billing-details/create-site-billing-details.scheme';
import {
  AnyObject,
  ISiteBillingDetailsFormData,
  ISiteBillingDetailsModel,
  PlaceType,
} from 'src/modules/types';
import {
  Api,
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from 'src/modules/utils';
import { FieldsContainer } from './components/FieldsContainer';
import {
  InvoiceContactDetailsFields,
  IValueChangeArgs,
} from './components/InvoiceContactDetailsFields';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { RequiredFiltersAlert } from 'src/components/RequiredFiltersAlert';

const initialValues = {
  siteId: NaN,
};

export const SiteBillingDetails = () => {
  const [invoiceBillToFields, setInvoiceBillToFields] = React.useState<
    ISiteBillingDetailsFormData[]
  >(generateInitialValues('invoice-bill-to'));
  const [invoiceQuestionsFields, setInvoiceQuestionsFields] = React.useState<
    ISiteBillingDetailsFormData[]
  >(generateInitialValues('invoice-questions'));

  const hasUserAccessToCreateBillingDetails = useHasUserAccessToAction(
    manageEntitiesConfig.site_billing_details.create.id,
  );
  const hasUserAccessToUpdateBillingDetails = useHasUserAccessToAction(
    manageEntitiesConfig.site_billing_details.update.id,
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const siteId = Number(useQueryParams().siteId);

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const validate = useValidate(createSiteBillingDetailsScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async ({ siteId }) => {
      const { toCreate, toUpdate, toDelete } = [
        ...invoiceBillToFields,
        ...invoiceQuestionsFields,
      ].reduce<any>(
        (parsed, item) => {
          if (item.value !== item.oldValue) {
            if (!item.oldValue && item.value) {
              parsed.toCreate.push(omit({ ...item, siteId }, 'oldValue'));
            }
            if (item.oldValue && item.value) {
              parsed.toUpdate.push(omit({ ...item, siteId }, 'oldValue'));
            }
            if (item.oldValue && !item.value && item.id) {
              parsed.toDelete.push(item.id);
            }
          }
          return parsed;
        },
        {
          toCreate: [],
          toUpdate: [],
          toDelete: [],
        },
      );

      if (toCreate.length) {
        await makeRequest(Api.SiteBillingDetails.createBulk, toCreate);
      }
      if (toUpdate.length) {
        await makeRequest(Api.SiteBillingDetails.bulkUpdate, toUpdate);
      }
      if (toDelete.length) {
        await makeRequest(Api.SiteBillingDetails.delete, {
          where: { id: { inq: toDelete } },
        });
      }

      const data = await fetchSiteBillingDetails(formik.values.siteId);

      initDataInForm(data, formik.values.siteId);

      dispatch(
        addProcessStatus({ message: t('common.success'), variant: 'success' }),
      );
    },
  });

  function initDataInForm(
    billingDetails: ISiteBillingDetailsModel[],
    siteId: number,
  ) {
    formik.setFieldValue('siteId', siteId);

    function updateState(items: ISiteBillingDetailsFormData[]) {
      return items.map((item) => {
        const existedData = billingDetails.find(
          (billingDetailsItem) =>
            billingDetailsItem.type === item.type &&
            billingDetailsItem.placeType === item.placeType,
        );

        if (existedData) {
          return {
            ...item,
            id: existedData.id,
            value: existedData.value,
            oldValue: existedData.value,
          };
        }

        return { ...item, value: '', oldValue: '', id: undefined };
      });
    }

    setInvoiceBillToFields(updateState);
    setInvoiceQuestionsFields(updateState);
  }

  const onSelectSite = async (
    _: React.ChangeEvent<AnyObject>,
    selectedSite: ComboBoxOption | null,
  ) => {
    setInvoiceBillToFields(generateInitialValues('invoice-bill-to'));
    setInvoiceQuestionsFields(generateInitialValues('invoice-questions'));

    if (!selectedSite) {
      pushSearchObject({});
      return;
    }

    try {
      pushSearchObject({ siteId: selectedSite.id });

      dispatch(setIsDataRequested(true));
      const data = await fetchSiteBillingDetails(selectedSite.id);
      dispatch(setIsDataRequested(false));

      initDataInForm(data, selectedSite.id);
    } catch {
      dispatch(setIsDataRequested(false));
    }
  };

  async function fetchSiteBillingDetails(siteId: number) {
    const data = await Api.SiteBillingDetails.list({
      filter: { where: { siteId } },
    });

    return data;
  }

  function generateInitialValues(placeType: PlaceType) {
    return [
      {
        placeType: placeType,
        type: 'name' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'email' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'street' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'phone-number' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'position-title' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'city-state-zip' as const,
        value: '',
        oldValue: '',
      },
      {
        placeType: placeType,
        type: 'additional-phone-number' as const,
        value: '',
        oldValue: '',
      },
    ];
  }

  async function makeRequest<T>(
    request: (args: T) => Promise<AnyObject | void>,
    args: T,
  ) {
    try {
      dispatch(setIsDataRequested(true));
      const response = await request(args);
      dispatch(setIsDataRequested(false));

      return response;
    } catch (e) {
      dispatch(setIsDataRequested(false));
      resetFormData();

      throw e;
    }
  }

  function resetFormData() {
    setInvoiceBillToFields((prev) =>
      prev.map((item) => ({ ...item, value: item.oldValue })),
    );
    setInvoiceQuestionsFields((prev) =>
      prev.map((item) => ({ ...item, value: item.oldValue })),
    );
  }

  const onInvoiceBillToValueChange = ({ value, path }: IValueChangeArgs) => {
    const newValues = set([...invoiceBillToFields], path, value);

    setInvoiceBillToFields(newValues);
  };

  const onInvoiceQuestionsValueChange = ({ value, path }: IValueChangeArgs) => {
    const newValues = set([...invoiceQuestionsFields], path, value);

    setInvoiceQuestionsFields(newValues);
  };

  const isSomeFieldDataChanged =
    invoiceBillToFields.some((field) => field.value !== field.oldValue) ||
    invoiceQuestionsFields.some((field) => field.oldValue !== field.value);

  // Initialize component using siteId from url's search in case it exists
  React.useEffect(() => {
    if (siteId) {
      initialize();
    }

    async function initialize() {
      try {
        formik.setFieldValue('siteId', siteId);

        dispatch(setIsDataRequested(true));
        const billingDetails = await fetchSiteBillingDetails(siteId);
        dispatch(setIsDataRequested(false));

        if (billingDetails.length) {
          initDataInForm(billingDetails, siteId);
        }
      } catch {
        dispatch(setIsDataRequested(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ maxWidth: 600, height: '100%' }}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography
          level="display_sm"
          fontWeight="medium"
          textColor="colors.text.text_primary.main"
          whiteSpace="nowrap"
          sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
        >
          {t('main_menu.site_billing_details.main_item')}
        </Typography>
      </Box>

      <Sheet sx={{ pb: 1 }}>
        <SitesComboBox
          id="siteId"
          required={true}
          formik={formik}
          placeholder={t('payboard.site')}
          onChange={onSelectSite}
        />
      </Sheet>

      {formik.values.siteId ? (
        <Box
          sx={{
            display: 'flex',
            // marginTop: 1,
            marginLeft: -0.5,
            marginRight: -0.5,
          }}
        >
          <FieldsContainer title={t('invoice.placements.invoice_bill_to')}>
            <InvoiceContactDetailsFields
              fields={invoiceBillToFields}
              onValueChange={onInvoiceBillToValueChange}
            />
          </FieldsContainer>
          <FieldsContainer title={t('invoice.placements.invoice_questions')}>
            <InvoiceContactDetailsFields
              fields={invoiceQuestionsFields}
              onValueChange={onInvoiceQuestionsValueChange}
            />
          </FieldsContainer>
        </Box>
      ) : (
        <RequiredFiltersAlert i18nKey="invoice.select_site_to_view_create_update_delete_billing_details" />
      )}
      {formik.values.siteId &&
      hasUserAccessToCreateBillingDetails &&
      hasUserAccessToUpdateBillingDetails ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="outlined"
            disabled={!isSomeFieldDataChanged}
            onClick={resetFormData}
          >
            {t('common.restore')}
          </Button>
          <Button
            disabled={!isSomeFieldDataChanged}
            sx={{ marginLeft: 2 }}
            variant="solid"
            type="submit"
            color="primary"
          >
            {t('common.save')}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
